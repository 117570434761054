import React from "react";

import moment from "moment";

import CustomAddToCalendarButton from "../../../../../components/CustomAddToCalendarButton/CustomAddToCalendarButton";

import RightSidebarCardStyles from "./RightSidebarCardStyles";

import { extractOPTDatesFromString } from "../../../../../utils/common";

const RightSidebarCard = ({
  title = "",
  value = "",
  valueType = "",
  threshold = 0,
  warningMessage = "",
  successMessage,
}) => {
  const extractedStartDate = extractOPTDatesFromString(value)?.length
    ? extractOPTDatesFromString(value)?.[0]
    : "";
  const extractedEndDate = extractOPTDatesFromString(value)?.length
    ? extractOPTDatesFromString(value)?.[1]
    : "";
  return (
    <RightSidebarCardStyles>
      <div className="opt_query-result">
        <h2 className="">{title}</h2>
        <p>{value !== null ? value : "-"}</p>
        {(valueType === "date" || valueType === "dateRange") &&
        value !== null ? (
          <CustomAddToCalendarButton
            name={title}
            startDate={
              valueType === "date" && value
                ? moment(value)?.format("YYYY-MM-DD")
                : valueType === "dateRange" && value && extractedStartDate
                ? moment(extractedStartDate)?.format("YYYY-MM-DD")
                : ""
            }
            endDate={
              valueType === "dateRange" && value && extractedEndDate
                ? moment(extractedEndDate)?.format("YYYY-MM-DD")
                : ""
            }
            options={[
              "Apple",
              "Google",
              "iCal",
              "Microsoft365",
              "MicrosoftTeams",
              "Outlook.com",
              "Yahoo",
            ]}
            buttonStyle="round"
          />
        ) : (
          ""
        )}
        {value >= threshold ? (
          <p className="warning-message">{warningMessage}</p>
        ) : successMessage ? (
          <p className="warning-message">{successMessage}</p>
        ) : (
          ""
        )}
      </div>
    </RightSidebarCardStyles>
  );
};

export default RightSidebarCard;
