import React, { useEffect } from "react";

import OPTQueryContainer from "../../../../../components/OPTCalculator/OPTQueryContainer/OPTQueryContainer";
import CustomDatePicker from "../../../../../components/CustomDatePicker/CustomDatePicker";

import DeadlinesOnPostCompletionOPTStyles from "./DeadlinesOnPostCompletionOPTStyles";

import useOPTPersistState from "../../../../../state/useOPTPersistState";

import { isFormattedDateValid } from "../../../../../utils/common";

const DeadlinesOnPostCompletionOPT = () => {
  const {
    optPersistState = {},
    updateDeadlinesOnPostCompletionOPT = () => {},
    disableNext = () => {},
  } = useOPTPersistState();
  const { optCalculatorState = {} } = optPersistState;
  const { deadlines_post_completion_opt = {} } = optCalculatorState;
  const { value = null } = deadlines_post_completion_opt; // null value is intended to keep the input clear and show placeholder
  const handleDateChange = (date) => {
    updateDeadlinesOnPostCompletionOPT({
      value: isFormattedDateValid(date, "LL") ? date : null,
    });
  };
  useEffect(() => {
    if (!value) {
      disableNext(true);
    } else {
      disableNext(false);
    }
  }, [value]);
  return (
    <DeadlinesOnPostCompletionOPTStyles>
      <OPTQueryContainer
        title={"Enter the program end date on the Form I-20: "}
      >
        <CustomDatePicker
          dateFormat={"MMMM dd, yyyy"}
          width={200}
          type="date"
          onChange={handleDateChange}
          value={value !== null ? new Date(value) : null}
          placeholder="Enter date"
          isClearable
        />
      </OPTQueryContainer>
    </DeadlinesOnPostCompletionOPTStyles>
  );
};

export default DeadlinesOnPostCompletionOPT;
