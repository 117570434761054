import axiosInstance from "../config/axiosInterceptors";
import { headers_with_token } from "../helper/helper";

/** All webibnars */
export const getWebinarsAPI = () => {
  return axiosInstance.get("webinars", headers_with_token()).then((res) => {
    return res.data;
  });
};

export const getUpcomingWebinarsAPI = () => {
  return axiosInstance
    .get("webinars/get_upcoming_webinars", headers_with_token())
    .then((res) => {
      return res.data;
    });
};

export const getPastWebinarsAPI = (page = 1, per_page = 5, options = {}) => {
  const queryString = Object.keys(options)
    .map((option) => `${option}=${options[option]}`)
    .join("&");
  let link = `webinars/get_past_webinars?page=${page}&per_page=${per_page}`;
  link = Object.keys(options).length > 0 ? `${link}&${queryString}` : link;
  return axiosInstance.get(link, headers_with_token()).then((res) => {
    return res.data;
  });
};

export const searchWebinarAPI = (search = "", host = "", categories = []) => {
  const categoryText =
    categories.length > 0
      ? `&${categories
          .map((category) => `category_id[]=${category}`)
          .join("&")}`
      : "";

  return axiosInstance
    .get(
      `webinars?search=${search}&host=${host}${categoryText}`,
      headers_with_token()
    )
    .then((res) => {
      return res.data;
    });
};

/** My Webinars */
export const getMyWebinarsAPI = () => {
  // let url = 'https://22319f108ff4.ngrok.io/api/v1/webinars/my_webinars_list';
  // let headers = {
  //   "Content-Type": "application/json",
  //   "X-User-Email": 'amu@narola.email',
  //   "X-User-Token": '51da466e3a45abe7e37f379e1b1160e4',
  // }

  // return axios.get(url, { headers: headers }).then((res) => {
  return axiosInstance
    .get("webinars/my_webinars_list", headers_with_token())
    .then((res) => {
      return res.data;
    });
};

export const getWebinarDetailsAPI = (webinarId) => {
  return axiosInstance
    .get(`webinars/${webinarId}`, headers_with_token())
    .then((res) => {
      return res.data;
    });
};
