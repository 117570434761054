import styled from "styled-components";
export default styled.div.attrs({ className: "" })`
  .loader-container {
    display: flex;
    .shimmer-card {
      .shimmer-avatar {
        height: 60px !important;
        width: 60px !important;
      }
    }
  }
  .messenger-page {
    > .MuiGrid-container {
      > .MuiGrid-item {
        &:first-child {
          max-width: 326px;
          flex-basis: 100%;
          width: 100%;
          @media (max-width: 767px) {
            max-width: 100%;
          }
        }
        &:nth-child(2) {
          max-width: calc(100% - 326px);
          flex-basis: 100%;
          width: 100%;
          @media (max-width: 767px) {
            max-width: 100%;
          }
        }
      }
    }
    &__header{
      position: relative;
      margin-bottom: 25px;
      &__title{
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        @media (max-width: 767px) {
          margin: 0 0 135px 0;
        }
        h2{
          color: #000C3D !important;
          font-family: "TTCommons-DemiBold";
          font-size: 22px;
          font-weight: 600;
          letter-spacing: 0;
          line-height: 24px;
        }
      }
      &__options{
        position: absolute;
        top: 0px;
        @media (max-width: 767px) {
          top: 50px;
        }
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 12px;
        width: 100%;
        &__search{
          @media (max-width: 767px) {
            width: 100%;
            display: flex;
            justify-content: center;
          }
          @media (min-width: 768px) {
            width: 310px;
            >div >div >div >div{
              width:100%
            }
          }
        }
        &__buttons{
          display: flex;
          @media (max-width: 767px) {
            width: 100%;
            display: flex;
            justify-content: center;
          }
          &__new-group{
            button{
              margin-right: 10px;
              width: auto;
              background: #fff !important;
              box-shadow: var(--card-box-shadow) !important;
              color: var(--primary-dark-color);
              padding: 16px 20px 12px;
              font-size: 18px;
              @media (max-width: 767px) {
                font-size: 16px;
              }
              font-weight: 500;
              letter-spacing: 0;
              line-height: 19px;
              font-family: "TTCommons-Medium";
              border-radius: 100px;
              text-decoration: none;
              transition: all 0.3s;
              display: inline-block;
              text-transform: initial;
              &:hover {
                box-shadow: 0 1px 14px 3px var(--gray-outline-color) !important;
              }
            }
          };
          &__new-messages{
            button{
              width: auto;
              background: #fff !important;
              box-shadow: var(--card-box-shadow) !important;
              color: var(--primary-dark-color);
              padding: 16px 20px 12px;
              font-size: 18px;
              @media (max-width: 767px) {
                font-size: 16px;
              }
              font-weight: 500;
              letter-spacing: 0;
              line-height: 19px;
              font-family: "TTCommons-Medium";
              border-radius: 100px;
              text-decoration: none;
              transition: all 0.3s;
              display: inline-block;
              text-transform: initial;
              &:hover {
                box-shadow: 0 1px 14px 3px var(--gray-outline-color) !important;
              }
            }
          }
        }
      }
    }
  }
`;
