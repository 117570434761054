import React from "react";
import { Field, Form, Formik } from "formik";
import { isEmpty } from "lodash";

import { useAppState } from "../../../context";
import { toastify } from "../../../helper/helper";
import usePromise from "../../../hooks/usePromise/usePromise";
import {
  getUserById,
  updateEmailConfirmation,
  editSecondaryEmail,
} from "../../../services/authenticationServices";
import { PRIMARY_COLOR } from "../../../styles/variables";
import ButtonCustom from "../../ButtonCustom/ButtonCustom";
import TextboxCustom from "../../Textbox/TextboxCustom";
import { validateEmail, signOutCall } from "../../../utils/common";
import usePersistState from "../../../state/usePersistState";
import useOPTPersistState from "../../../state/useOPTPersistState";

export default function EmailUpdateDialog({ setUpdateEmailPopUp, type }) {
  const { profile, updateLocalEditSecondaryEmail } = useAppState("profile");
  const { users } = usePersistState();
  const { resetOPT = () => {} } = useOPTPersistState();
  const [, refreshgetUserById] = usePromise(getUserById);

  const validateFields = (values) => {
    let errors = {};
    // Standard Validation
    if (!values.email) {
      errors.email = "Email is required";
    } else if (!validateEmail(values.email)) {
      errors.email = "Please enter a valid email";
    }
    // Type based validation
    if (type === "primary") {
      if (values.email === profile.personal.email) {
        errors.email = "Please enter a new primary email";
      } else if (
        profile.personal.secondary_email?.length > 0 &&
        values.email === profile.personal.secondary_email
      ) {
        errors.email = "Primary email cannot be same as secondary";
      }
    } else {
      if (values.email === profile.personal.secondary_email) {
        errors.email = "Please enter a new secondary email";
      } else if (values.email === profile.personal.email) {
        errors.email = "Secondary email cannot be same as primary";
      }
    }

    return errors;
  };

  const handleSubmit = async (values, actions) => {
    try {
      if (type === "secondary") {
        if (profile.personal.secondary_email === values.email) {
          toastify(
            "error",
            "Please enter a different secondary email address."
          );
          actions.setSubmitting(false);
        } else {
          // Call Edit secondary email API
          try {
            const response = await editSecondaryEmail({
              secondary_email: values.email,
            });
            if (response.success) {
              toastify(
                "success",
                response.message
                  ? response.message
                  : "A confirmation email has been sent to your updated secondary email account."
              );
              actions.setSubmitting(false);
              setUpdateEmailPopUp(false);
              // Update local personal form data
              updateLocalEditSecondaryEmail(values.email, false);
            }
          } catch (error) {
            console.error(error);
            toastify(
              "error",
              error?.data?.message
                ? error.data.message
                : "Oops! Something went wrong"
            );
          }
        }
      } else {
        if (profile.personal.email === values.email) {
          toastify("error", "Please enter a different email address.");
          actions.setSubmitting(false);
        } else {
          const response = await updateEmailConfirmation(values);
          if (response) {
            toastify(
              "success",
              "A confirmation email has been sent to your updated email account."
            );
            actions.setSubmitting(false);
            setUpdateEmailPopUp(false);
            refreshgetUserById(users?.id);
            // Old Implementation
            // refreshgetUserById( updatedUserDetails.id);
            setTimeout(() => {
              resetOPT();
              signOutCall(users.school_id);
            }, 800);
          }
        }
      }
    } catch (err) {
      toastify(
        "error",
        err && err.data ? err.data.message : "Something went wrong"
      );
    }
  };

  return (
    <Formik
      initialValues={{
        email:
          type === "secondary"
            ? profile?.personal?.secondary_email
            : profile?.personal?.email,
      }}
      validate={validateFields}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, errors, ...props }) => (
        <Form>
          <div className="mt-15 change-email-form">
            <Field name="email" {...props} type="select" className="input-wrap">
              {({ field, form: { errors }, meta }) => (
                <TextboxCustom
                  {...field}
                  label={
                    type === "secondary"
                      ? "Your new secondary email*"
                      : "Your new email*"
                  }
                  name="email"
                  placeholder={
                    type === "secondary"
                      ? "Your new secondary email"
                      : "Your new email"
                  }
                  lableaignment="left"
                  textcolor={PRIMARY_COLOR}
                  error={meta.touched && !isEmpty(errors["email"])}
                  errorText={meta.touched && meta.error}
                />
              )}
            </Field>
            <div className="checkbox-wrap">
              {type === "secondary" ? (
                <>
                  <p className="mt-0">
                    You will receive a confirmation email for updated email
                    address.
                  </p>
                </>
              ) : (
                <>
                  <p className="mt-0">
                    Updating the email will sign you out from all active
                    sessions.
                  </p>
                  <p>
                    You will receive a confirmation email for updated email
                    address.
                  </p>
                </>
              )}
            </div>
            <div className="customized-button">
              <ButtonCustom
                isSubmitting={isSubmitting}
                isDisabled={!isEmpty(errors) || !props.values.email}
                type="submit"
              >
                Apply Changes
              </ButtonCustom>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}
