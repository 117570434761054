import React from "react";

import OPTReviewStyles from "./OPTReviewStyles";

import useOPTPersistState from "../../../state/useOPTPersistState";

import optCalculatorIcon from "../../../assets/svg/opt-calculator-icon.svg";

const OPTReview = () => {
  const { resetOPT = () => {} } = useOPTPersistState();

  return (
    <OPTReviewStyles>
      <h2 className="opt_full-title">
        Review and recalculate your results by clicking on any of the steps.
      </h2>
      <div className="img-container">
        <img src={optCalculatorIcon} alt="OPT calculator" />
      </div>
      <p>
        Or if you want to clear your results and restart the questionnaire{" "}
        <a className="ngray-link" href onClick={resetOPT}>
          click here.
        </a>
      </p>
    </OPTReviewStyles>
  );
};

export default OPTReview;
