import React, { memo } from "react";
import { ImpulseSpinner } from "react-spinners-kit";
import { Avatar } from "@material-ui/core";

import { createModuleStyleExtractor } from "../../../../utils/css";
import CustomUploadV2 from "../../../CustomUploadV2/CustomUpload";
import styles from "./UploadImage.module.scss";

const cx = createModuleStyleExtractor(styles);

const UploadImage = ({ handleCallback, user, onClear, imageUpdating }) => {
  const { profile_image_url, admission_user_type } = user || {};

  return (
    <div className={cx("image-upload-container")}>
      <span className={cx("image-upload-container__heading")}>
        Upload profile picture{admission_user_type ? "*" : ""}
      </span>
      <div className={cx("image-upload-container__images")}>
        {imageUpdating ? (
          <div className={cx("image-upload-container__images__loader")}>
            <ImpulseSpinner
              size={40}
              backColor="#b0b1d0"
              frontColor="#000485"
            />
          </div>
        ) : (
          <>
            <Avatar
              alt={"profile picture"}
              src={profile_image_url}
              style={{ width: 50, height: 50 }}
            />
            <CustomUploadV2
              handleUploadFile={handleCallback}
              handleRemoveFile={onClear}
              uploadText="Upload picture"
              removeText="Remove picture"
              preview={profile_image_url}
            />
          </>
        )}
      </div>
    </div>
  );
};
export default memo(UploadImage);
