import React, { useState, useEffect } from "react";
import { Grid, Popover, Typography } from "@material-ui/core";
import { isEmpty } from "lodash";

import BoostProfileLeftSidebar from "../../../components/BoostProfile/BoostProfileLeftSidebar/BoostProfileLeftSidebar";
import ButtonCustom from "../../../components/ButtonCustom/ButtonCustom";
import OPTCalculatorMiddle from "./OPTCalculatorMiddle/OPTCalculatorMiddle";
import OPTCalculatorRightSidebar from "./OPTCalculatorRightSidebar/OPTCalculatorRightSidebar";

import OPTCalculatorStyles from "./OPTCalculatorStyles";

import useOPTPersistState from "../../../state/useOPTPersistState";

import Notealert from "../../../assets/svg/note-alert.svg";

const OPTCalculator = () => {
  window.document.title = "OPT Calculator"; // Assigns page title
  const accessibilityHelp = localStorage.getItem("accessibilityHelp");
  const {
    optPersistState = {},
    updateOPTIndex = () => {},
    resetOPT = () => {},
  } = useOPTPersistState();

  const {
    optCalculatorState = {},
    optTotalCompletedStatus = 0,
    optStatusList = [],
    optRightSidebarStatus = [],
    optIndex = null,
  } = optPersistState;

  const [showPopover, setPopover] = useState(null);
  const openPopover = Boolean(showPopover);
  const idPopover = openPopover ? "opt-title-popover" : undefined;

  useEffect(() => {
    if (
      optRightSidebarStatus?.length === 0 ||
      optStatusList?.length === 0 ||
      isEmpty(optCalculatorState)
    ) {
      resetOPT();
    }
  }, []);

  const handleInfoClick = (event) => {
    event.preventDefault();
    setPopover(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setPopover(null);
  };

  return (
    <OPTCalculatorStyles>
      <div className="custom-container">
        <div
          className={`opt-calculator-content ${
            optIndex === null ? "opt-intro-page" : ""
          }`}
        >
          <h2 className="f-main-title">
            OPT Calculator
            <a
              href="/#"
              onClick={handleInfoClick}
              className="alert-icon information-icon"
              aria-label="Find Job Note"
            >
              <img src={Notealert} alt="Note" />
            </a>
          </h2>

          <Popover
            id={idPopover}
            open={openPopover}
            anchorEl={showPopover}
            onClose={handlePopoverClose}
            className="popover-wrapper"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <Typography>
              This planning tool for post-competition OPT is not a substitute
              for applicable legal requirements, nor is it itself a rule or
              final action by SEVP. Learn more about the OPT calculator:{" "}
              <a
                href="https://studyinthestates.dhs.gov/sevis-help-hub/student-records/fm-student-employment/planning-tool-for-post-completion-opt"
                target="_blank"
                rel="noopener noreferrer"
                className="popover-link"
              >
                Planning Tool for Post Completion OPT
              </a>
            </Typography>
            {accessibilityHelp === "true" && (
              <div className="close-button">
                <ButtonCustom onClick={handlePopoverClose}>
                  Got It!
                </ButtonCustom>
              </div>
            )}
          </Popover>
          <Grid container spacing={3}>
            <Grid item xs={3}>
              {optIndex !== null && optIndex >= 0 ? (
                <BoostProfileLeftSidebar
                  sidebarTitle={false}
                  statusType={"opt"}
                  currentIndex={optIndex}
                  handleClickStatus={(idx) => updateOPTIndex(idx)}
                  statusList={optStatusList}
                  totalCompletedStatus={optTotalCompletedStatus}
                />
              ) : (
                ""
              )}
            </Grid>
            <Grid item xs={6}>
              <OPTCalculatorMiddle />
            </Grid>
            <Grid item xs={3}>
              {optIndex !== null && optIndex >= 0 && optIndex < 6 ? (
                <OPTCalculatorRightSidebar />
              ) : (
                ""
              )}
            </Grid>
          </Grid>
        </div>
      </div>
    </OPTCalculatorStyles>
  );
};

export default OPTCalculator;
