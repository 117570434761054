import React, { useState, useEffect } from "react";
import filter from "underscore/modules/filter";
import values from "underscore/modules/values";
import { Grid, Avatar } from "@material-ui/core";
import { useLocation, navigate } from "@reach/router";
import { isEmpty, isNull } from "lodash";
import { Field, Form, Formik } from "formik";

import SearchResultStyles from "./SearchResultStyles";
import usePromise from "../../../hooks/usePromise/usePromise";
import usePersistState from "../../../state/usePersistState";
import { useAppState } from "../../../context";
import {
  toastify,
  numberWithCommas,
  capitalizeFirstLetterOnType,
  extractUniqueAndGroupJobs,
  processJobLocationTime,
} from "../../../helper/helper";
import {
  NOTIFICATION_FREQUENCY,
  US_STATE_OPTIONS,
  BACKEND_FLAG_URL,
} from "../../../constant/mockdata";
import SaveJobList from "../../../components/Jobs/SaveJobsList/SaveJobsList";
import SearchList from "../../../components/Jobs/SearchResult/SearchList";
import SearchFilter from "../../../components/Jobs/SearchFilter/SearchFilter";
import CustomDialog from "../../../components/CustomDialog/CustomDialog";
import ButtonCustom from "../../../components/ButtonCustom/ButtonCustom";
import TextboxCustom from "../../../components/Textbox/TextboxCustom";
import DropdownCustom from "../../../components/DropdownCustom/DropdownCustom";
import BoostPopup from "../../../components/BoostDialog/BoostDialog";
import InitialAvatar from "../../../components/InitialAvatar/InitialAvatar";
import JobsListingEffect from "../../../components/effects/JobsListingEffect/JobsListingEffect";
import SaveSearchList from "../../../components/Jobs/SaveSearchList/SaveSearchList";
import {
  searchJobsAPI,
  searchTopEmployerJobsAPI,
  saveJobsSearchAPI,
  getFeaturedJobsAPI,
} from "../../../services/JobServices";
import { getFilteredEmployerJobsAPI } from "../../../services/EmployerJobServices";
import { getRecommendedNetworksAPI } from "../../../services/networkServices";

const SearchResult = () => {
  let query = new URLSearchParams(useLocation().search);
  let countryParam = query.get("country");

  const { statusList, setStatusList } = useAppState("boostProfile");
  const {
    jobs = [],
    setJobs,
    jobsFilter,
    setJobsFilter,
    grouped_top_employer_jobs,
    setGroupedTopEmployerJobs,
  } = useAppState("job");
  const { users, jobsCountryList, permissions = {} } = usePersistState();

  const [currentJobsFilter, setCurrentJobsFilter] = useState({});
  const [appliedJobsFilters, setAppliedJobsFilter] = useState({});
  const [sortBy, setSortBy] = useState("date");
  const [savedJobsFilter, setSavedJobsFilter] = useState({});
  const [savedFeaturedJobsFilter, setSavedFeaturedJobsFilter] = useState({});
  const [modelData, setModelData] = useState(null);
  const [searchTitle, setSearchTitle] = useState(null);
  const [notificationFrequency, setNotificationFrequency] = useState(null);
  const [showBoostPopup, setShowBoostPopup] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalJobs, setTotalJobs] = useState(0);
  const [callSearchJobsAPI, refreshSearchJobsAPI] = usePromise(searchJobsAPI);
  const [callSearchTopEmployerJobsAPI, refreshSearchTopEmployerJobsAPI] =
    usePromise(searchTopEmployerJobsAPI);
  const [totalTrueValues, settotalTrueValues] = useState(0);
  const [callSaveJobsSearchAPI, refreshSaveJobsSearchAPI] =
    usePromise(saveJobsSearchAPI);
  const [refreshSearches, setRefreshSearches] = useState(1);

  const [showSaveSearch, setShowSaveSearch] = useState(false);
  const [showLoader, setLoader] = useState(false);
  const [adminJobsList, setAdminJobsList] = useState([]);
  const [adminJobsListGrouped, setAdminJobsListGrouped] = useState({});
  const [callAdminJobsAPI, refreshAdminJobsAPI] =
    usePromise(getFeaturedJobsAPI);
  const [totalAdminJobs, setTotalAdminJobs] = useState(0);
  const [employerJobsList, setEmployerJobsList] = useState([]);
  const [employerJobsListGrouped, setEmployerJobsListGrouped] = useState({});
  const [callEmployerJobsAPI, refreshEmployerJobsAPI] = usePromise(
    getFilteredEmployerJobsAPI
  );
  const [totalEmployerJobs, setTotalEmployerJobs] = useState(0);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [
    callingGetRecommendedNetWorkPeopleAPI,
    refreshGetRecommendedNetWorkPeopleAPI,
  ] = usePromise(getRecommendedNetworksAPI);
  const [networkPeopleList, setNetworkPeopleList] = useState([]);
  const [showFeaturedJobs, setShowFeaturedJobs] = useState(true);

  const {
    search,
    country,
    state,
    city,
    visa,
    job_type,
    publish_date,
    degree,
    experience,
    job_search_type,
  } = currentJobsFilter;

  const searchType = country && country === "us" ? "us" : "international";

  useEffect(() => {
    setSortBy("date");

    let {
      keyword = "",
      country = "us",
      search_type = "top_1000_employers",
      state = null,
      city = null,
      publish_date = null,
      degree = null,
      experience = null,
      job_type = null,
      job_search_type = "approx",
    } = jobsFilter;

    if (countryParam) country = countryParam;

    const defaultFilters = { search: keyword };
    if (country && country !== null) {
      setShowSaveSearch(true);

      defaultFilters.country = country ? country : "us";
      //Set only if the country is US
      if (country === "us") {
        defaultFilters.visa = search_type;
        defaultFilters.state = state;
      }
    }

    if (city && city !== null) defaultFilters.city = city;
    if (publish_date && publish_date !== null)
      defaultFilters.publish_date = publish_date;
    if (job_type && job_type !== null) defaultFilters.job_type = job_type;
    if (degree && degree !== null) defaultFilters.degree = degree;
    if (experience && experience !== null)
      defaultFilters.experience = experience;

    setCurrentJobsFilter({ ...defaultFilters, job_search_type });

    const params = {
      job_region: country && country === "us" ? "us" : "international",
      search: keyword === null ? "" : keyword,
      country,
      visa:
        country !== null && country === "us" && search_type !== null
          ? search_type
          : "",
      state:
        country !== null && country === "us" && state !== null ? state : "",
      city,
      job_type,
      publish_date,
      degree,
      experience,
      job_search_type,
    };

    applyFilters(params);

    setJobsFilter({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (country && jobsCountryList.length > 0) {
      const selectedCountry = jobsCountryList.find(
        (rec) => rec.value === country
      );
      setSelectedCountry(selectedCountry);
      if (permissions["chat"] && selectedCountry?.label)
        refreshGetRecommendedNetWorkPeopleAPI({
          country: selectedCountry?.label,
        });
      else setNetworkPeopleList([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country]);

  const applyFilters = (params = {}) => {
    setJobs([]);
    setGroupedTopEmployerJobs({});
    setLoader(true);
    const defaultParams = {
      job_region: searchType,
      search: search === null ? "" : search,
      country,
      city,
      visa: country && country === "us" && visa !== null ? visa : "",
      job_type,
      publish_date,
      degree,
      experience,
      job_search_type,
      state,
      page: 1,
      ...params,
    };

    /* Featured Job **/
    const featuredJobsParams = {
      search: defaultParams.search !== "" ? defaultParams.search : null,
      city: defaultParams.city !== "" ? defaultParams.city : null,
      publish_date:
        defaultParams.publish_date && defaultParams.publish_date !== ""
          ? publish_date
          : null,
      job_type: defaultParams.job_type,
    };

    if (!isNull(defaultParams.country) && jobsCountryList.length > 0) {
      const selectedCountry = jobsCountryList.find(
        (rec) => rec.value === defaultParams.country
      );
      if (selectedCountry) featuredJobsParams.country = selectedCountry.label;
    }

    if (country && country === "us") {
      const selectedState =
        state !== null
          ? US_STATE_OPTIONS.find((rec) => rec.value === defaultParams.state)
          : null;

      if (selectedState && selectedState !== null)
        featuredJobsParams.state = selectedState.value;

      if (
        selectedState &&
        selectedState !== null &&
        (defaultParams.visa === "top_500_employers" ||
          defaultParams.visa === "top_1000_employers" ||
          defaultParams.visa === "cap_exempt" ||
          defaultParams.visa === "sponsered_last_year")
      ) {
        defaultParams.state = selectedState.value;
      } else if (visa === "all_companies") {
        if (selectedState && selectedState !== null) {
          defaultParams.location = `${
            city && city !== null && city.length > 0 ? city : ""
          }${
            city &&
            city !== null &&
            city.length > 0 &&
            selectedState &&
            selectedState !== null &&
            selectedState.label
              ? ", "
              : ""
          }${
            selectedState && selectedState.label ? `${selectedState.label}` : ""
          }`;
        } else {
          defaultParams.location = `${
            city && city !== null && city.length > 0 ? city : ""
          }`;
        }
      }
    } else {
      defaultParams.location = `${
        city && city !== null && city.length > 0 ? city : ""
      }`;
    }

    setCurrentPage(1);

    /* Featured Jobs**/
    setEmployerJobsList([]);
    setAdminJobsList([]);

    // No featured jobs for sponsered_last_year, cap_exempt visa type + experience, degree and position filter
    if (
      defaultParams.visa === "sponsered_last_year" ||
      defaultParams.visa === "cap_exempt" ||
      defaultParams.experience ||
      defaultParams.degree ||
      defaultParams.job_type
    ) {
      setShowFeaturedJobs(false);
    } else {
      setShowFeaturedJobs(true);
      refreshEmployerJobsAPI(users?.id, {
        ...featuredJobsParams,
        sort: sortBy,
      });
      refreshAdminJobsAPI({
        ...featuredJobsParams,
        sort: sortBy,
      });
    }

    if (
      defaultParams.country === "us" &&
      (defaultParams.visa === "top_1000_employers" ||
        defaultParams.visa === "top_500_employers" ||
        defaultParams.visa === "cap_exempt" ||
        defaultParams.visa === "sponsered_last_year")
    ) {
      refreshSearchTopEmployerJobsAPI({ ...defaultParams, sort: sortBy });
    } else {
      refreshSearchJobsAPI({ ...defaultParams, sort: sortBy });
    }

    setSavedJobsFilter(defaultParams);
    setSavedFeaturedJobsFilter(featuredJobsParams);
  };

  const getPaginationData = () => {
    const defaultParams = {
      ...savedJobsFilter,
      page: currentPage + 1,
    };

    setCurrentPage(currentPage + 1);
    if (
      defaultParams.country === "us" &&
      (defaultParams.visa === "top_500_employers" ||
        defaultParams.visa === "top_1000_employers" ||
        defaultParams.visa === "cap_exempt" ||
        defaultParams.visa === "sponsered_last_year")
    )
      refreshSearchTopEmployerJobsAPI({ ...defaultParams, sort: sortBy });
    else refreshSearchJobsAPI({ ...defaultParams, sort: sortBy });
  };

  const updateSortBy = (newSortBy) => {
    setJobs([]);

    const defaultParams = {
      ...savedJobsFilter,
      page: 1,
    };

    setLoader(true);
    setSortBy(newSortBy);

    /* Featured Jobs**/
    refreshEmployerJobsAPI(users?.id, {
      ...savedFeaturedJobsFilter,
      sort: newSortBy,
    });
    refreshAdminJobsAPI({
      ...savedFeaturedJobsFilter,
      sort: newSortBy,
    });

    if (
      defaultParams.country === "us" &&
      (defaultParams.visa === "top_500_employers" ||
        defaultParams.visa === "top_1000_employers" ||
        defaultParams.visa === "cap_exempt" ||
        defaultParams.visa === "sponsered_last_year")
    )
      refreshSearchTopEmployerJobsAPI({ ...defaultParams, sort: newSortBy });
    else refreshSearchJobsAPI({ ...defaultParams, sort: newSortBy });
  };

  useEffect(() => {
    if (
      callSearchJobsAPI.hasFetched() &&
      callSearchJobsAPI.hasErrors() === false &&
      callSearchJobsAPI.data() &&
      callSearchJobsAPI.data().data
    ) {
      setTotalPages(callSearchJobsAPI.data().data.total_pages);
      setTotalJobs(callSearchJobsAPI.data().data.total_jobs);

      const formattedJobs = callSearchJobsAPI.data()?.data?.jobs.map((rec) => {
        const formattedRec = {
          ...rec,
        };
        // Need job location to compare group jobs based on location as well
        formattedRec.interstride_location = processJobLocationTime({
          country: selectedCountry?.label ?? formattedRec?.country,
          state: formattedRec?.state,
          city: formattedRec?.city,
          time: null,
        });
        return formattedRec;
      });

      // Compute formatted unique jobs and grouped jobs
      const { uniqueJobs, grouped_state_jobs } = extractUniqueAndGroupJobs(
        formattedJobs,
        grouped_top_employer_jobs,
        "search"
      );

      setJobs([...jobs, ...uniqueJobs]);
      setGroupedTopEmployerJobs(grouped_state_jobs); // Update Jobs app state of group jobs
      setLoader(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callSearchJobsAPI.isFetching()]);

  useEffect(() => {
    if (
      callSearchTopEmployerJobsAPI.hasFetched() &&
      callSearchTopEmployerJobsAPI.hasErrors() === false &&
      callSearchTopEmployerJobsAPI.data() &&
      callSearchTopEmployerJobsAPI.data().data
    ) {
      setTotalPages(callSearchTopEmployerJobsAPI.data().data.total_pages);
      setTotalJobs(callSearchTopEmployerJobsAPI.data().data.total_jobs);
      const formattedJobs = callSearchTopEmployerJobsAPI
        .data()
        .data.jobs.map((rec) => {
          const formattedRec = {
            ...rec,
            ...rec.api_response,
          };
          delete formattedRec.api_response;

          // Need job location to compare group jobs based on location as well
          formattedRec.interstride_location = processJobLocationTime({
            country: selectedCountry?.label ?? formattedRec?.country,
            state: formattedRec?.state,
            city: formattedRec?.city,
            time: null,
          });

          return formattedRec;
        });

      // Compute formatted unique jobs and grouped jobs
      const { uniqueJobs, grouped_state_jobs } = extractUniqueAndGroupJobs(
        formattedJobs,
        grouped_top_employer_jobs,
        "search"
      );

      setJobs([...jobs, ...uniqueJobs]);
      setGroupedTopEmployerJobs(grouped_state_jobs); // Update Jobs app state of group jobs
      setLoader(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callSearchTopEmployerJobsAPI.isFetching()]);

  useEffect(() => {
    if (
      callEmployerJobsAPI.hasFetched() &&
      callEmployerJobsAPI.hasErrors() === false
    ) {
      if (callEmployerJobsAPI.data() && callEmployerJobsAPI.data().data) {
        const { employer_jobs = [], employer_jobs_count = 0 } =
          callEmployerJobsAPI.data().data;
        // Compute formatted unique jobs and grouped jobs
        const { uniqueJobs, grouped_state_jobs } = extractUniqueAndGroupJobs(
          employer_jobs,
          employerJobsListGrouped,
          "employer"
        );
        setTotalEmployerJobs(employer_jobs_count);
        setEmployerJobsList(uniqueJobs);
        setEmployerJobsListGrouped(grouped_state_jobs);
      } else {
        setEmployerJobsList([]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callEmployerJobsAPI.isFetching()]);

  useEffect(() => {
    if (
      callAdminJobsAPI.hasFetched() &&
      callAdminJobsAPI.hasErrors() === false
    ) {
      if (
        callAdminJobsAPI.data() &&
        callAdminJobsAPI.data().data &&
        callAdminJobsAPI.data().data.jobs
      ) {
        const { jobs = [], total_jobs = 0 } = callAdminJobsAPI.data().data;

        // Compute formatted unique jobs and grouped jobs
        const { uniqueJobs, grouped_state_jobs } = extractUniqueAndGroupJobs(
          jobs,
          adminJobsListGrouped,
          "admin"
        );
        setTotalAdminJobs(total_jobs);
        setAdminJobsList(uniqueJobs);
        setAdminJobsListGrouped(grouped_state_jobs);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callAdminJobsAPI.isFetching()]);

  const fetchMoreData = () => {
    getPaginationData();
  };

  const handleSaveSearchClick = () => {
    handleModel({
      open: true,
      title: "Save this search",
      description:
        "You’ll be able to easily repeat this search and receive notifications of related new Job listing.",
      btnText: "Save Search",
      searchText: search,
      defaultFrequency: "no_notification",
      cancelLink: true,
    });
  };

  const handleModel = (data) => {
    setModelData(data);
    if (data.open === false) {
      setSearchTitle(null);
      setNotificationFrequency(null);
    } else {
      setNotificationFrequency(data.defaultFrequency);
    }
  };

  // Set applied jobs filters once all data is loaded and loader stops
  useEffect(() => {
    if (!showLoader) setAppliedJobsFilter({ ...currentJobsFilter });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showLoader]);

  const clearAll = (specificFilter = false) => {
    setLoader(true);

    setCurrentJobsFilter({
      ...currentJobsFilter,
      ...(specificFilter
        ? specificFilter
        : {
            search: "",
            country: "us",
            visa: "top_1000_employers",
            state: null,
            city: "",
            publish_date: null,
            degree: null,
            experience: null,
            job_type: null,
            job_search_type: "approx",
          }),
    });

    applyFilters({
      ...(specificFilter
        ? { ...currentJobsFilter, ...specificFilter }
        : {
            search: "",
            country: "us",
            visa: "top_1000_employers",
            state: null,
            city: null,
            publish_date: null,
            degree: null,
            experience: null,
            job_type: null,
            job_search_type: "approx",
          }),
    });
  };

  useEffect(() => {
    if (callSaveJobsSearchAPI.hasFetched()) {
      if (callSaveJobsSearchAPI.hasErrors() === false) {
        toastify("success", callSaveJobsSearchAPI.data().message);
        handleModel({ open: false });
        // Need to set to refresh count
        setRefreshSearches(refreshSearches + 1);

        if (
          callSaveJobsSearchAPI.data().data &&
          callSaveJobsSearchAPI.data().data.boost_profile &&
          callSaveJobsSearchAPI.data().data.boost_profile.job_search
        ) {
          setStatusList(callSaveJobsSearchAPI.data().data.boost_profile);

          const response = filter(
            values(callSaveJobsSearchAPI.data().data.boost_profile),
            function (data) {
              return data === true;
            }
          ).length;

          settotalTrueValues(response);
          setShowBoostPopup(true);
        }
      } else
        toastify(
          "error",
          callSaveJobsSearchAPI?._error?.data?.message ??
            "Unable to save search!"
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callSaveJobsSearchAPI.isFetching()]);

  const callSaveSearchAPI = () => {
    const defaultParams = {
      keyword: !search ? "" : search,
      country,
      city,
      state,
      job_type,
      publish_date,
      search_type:
        country && country === "us" && visa !== null ? visa : "international",
      sort: sortBy,
      notification_frequency: notificationFrequency,
      title: searchTitle,
      experience,
      degree,
      job_search_type,
    };

    refreshSaveJobsSearchAPI(defaultParams);
  };

  useEffect(() => {
    if (
      callingGetRecommendedNetWorkPeopleAPI.hasFetched() &&
      callingGetRecommendedNetWorkPeopleAPI.hasErrors() === false
    ) {
      setNetworkPeopleList(
        callingGetRecommendedNetWorkPeopleAPI.data().data.recommended_network
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callingGetRecommendedNetWorkPeopleAPI.isFetching()]);

  const closeFN = () => {
    setShowBoostPopup(false);
  };

  const RecommendedNetworkCard = (
    <React.Fragment>
      {networkPeopleList.length > 0 && (
        <div className="recommended-wrapper">
          <div className="recommended-network-dialog">
            <div className="recommended-header">
              <div className="main-title">
                Connect with your network from{" "}
                {selectedCountry ? selectedCountry.label : ""}
              </div>
            </div>
            {networkPeopleList.slice(0, 3).map((network, index) => (
              <div className="recommended-card row d-flex" key={index}>
                <div className="col-md-4">
                  {!network.user_profile || isEmpty(network.user_profile) ? (
                    <InitialAvatar title={network.full_name} index={index} />
                  ) : (
                    <Avatar alt="User" src={network.user_profile} />
                  )}
                </div>
                <div className="col-md-8">
                  <div className="user-title">{network.full_name}</div>
                  <div className="row d-flex user-sub-title">
                    {network.major}
                    {network.major && network.graduation_year && (
                      <span className="left-right-space-span">|</span>
                    )}
                    Year {network.graduation_year}
                  </div>
                </div>
              </div>
            ))}

            <div style={{ marginTop: "20px" }}>
              <a
                href
                className="nlink-secondary"
                onClick={(e) => {
                  e.preventDefault();
                  navigate(
                    `/network/discover?country=${selectedCountry.value}`
                  );
                }}
                keepLabelCase={true}
              >
                Recommended connections
              </a>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );

  const numberOfJobs = !showFeaturedJobs
    ? numberWithCommas(totalJobs)
    : numberWithCommas(totalJobs + totalAdminJobs + totalEmployerJobs);

  return (
    <SearchResultStyles>
      <div className="search-mainpage">
        <div className="job-section">
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <SearchFilter
                filter={currentJobsFilter}
                onClearAllClick={clearAll}
                setFilter={(changedFilters) => {
                  setCurrentJobsFilter({
                    ...currentJobsFilter,
                    ...changedFilters,
                  });
                }}
                applyFilters={() => {
                  setShowSaveSearch(true);
                  applyFilters();
                }}
                showSaveSearch={showSaveSearch}
                handleSaveSearchClick={handleSaveSearchClick}
              />

              {permissions["chat"] && (
                <div className="ShowResponsiveDiv">
                  {RecommendedNetworkCard}
                </div>
              )}
            </Grid>

            <Grid item xs={6}>
              {showLoader ||
              callEmployerJobsAPI.isFetching() ||
              callAdminJobsAPI.isFetching() ? (
                <JobsListingEffect count={10} />
              ) : (
                <SearchList
                  showFeaturedJobs={showFeaturedJobs}
                  source="jobSearch"
                  subTitle={
                    numberOfJobs === "0"
                      ? "Job Listing"
                      : "Job listing (" + numberOfJobs + ")"
                  }
                  showSortBy={false}
                  fetchMoreData={fetchMoreData}
                  totalPages={totalPages}
                  currentPage={currentPage}
                  sortBy={sortBy}
                  updateSortBy={updateSortBy}
                  employerJobsList={employerJobsList}
                  employerJobsListGrouped={employerJobsListGrouped}
                  adminJobsList={adminJobsList}
                  adminJobsListGrouped={adminJobsListGrouped}
                  showPaginationLoader={
                    callEmployerJobsAPI.isFetching() ||
                    callAdminJobsAPI.isFetching() ||
                    callSearchJobsAPI.isFetching() ||
                    callSearchTopEmployerJobsAPI.isFetching()
                  }
                  showClearableFilters
                  appliedJobsFilters={appliedJobsFilters}
                  onClearFilterClick={clearAll}
                  showSaveSearch={showSaveSearch}
                  handleSaveSearchClick={handleSaveSearchClick}
                />
              )}
            </Grid>

            <Grid item xs={3}>
              <SaveJobList isWidget={true} backPage="search" />
              <SaveSearchList
                isWidget={true}
                showCount={true}
                refreshSearches={refreshSearches}
                backPage="search"
              />
              {permissions["country_insights"] && selectedCountry && (
                <div className="cityjobguide-wrapper">
                  <div className="cityjobguide">
                    <div className="flag-icon">
                      <img
                        src={`${BACKEND_FLAG_URL}${
                          selectedCountry ? selectedCountry.value : ""
                        }.svg`}
                        alt="Flag icon"
                      />
                    </div>
                    <h3>
                      Our {selectedCountry ? selectedCountry.label : ""} Guide
                    </h3>
                    <p>
                      Learn about relocating, visa & work permits, housing, and
                      more.
                    </p>
                    <a
                      href="/#"
                      className="nlink-secondary"
                      onClick={(e) => {
                        e.preventDefault();
                        navigate(
                          `/jobs/country-insights?country=${selectedCountry.value}`
                        );
                      }}
                      keepLabelCase={true}
                    >
                      View Country Insights
                    </a>
                  </div>
                </div>
              )}

              {permissions["chat"] && <>{RecommendedNetworkCard}</>}
            </Grid>
          </Grid>
          {modelData && modelData.open && (
            <CustomDialog
              open={modelData.open}
              title={modelData.title}
              subTitle={modelData.description}
              handleClose={() => {
                handleModel({ ...modelData, open: false });
              }}
              className={`save-search-modal ${
                modelData.type === "subscribe" ? "subscribe-modal" : ""
              }`}
            >
              <Formik
                initialValues={{ searchTitle: "" }}
                isInitialValid={false}
                validate={(values) => {
                  let errors = {};

                  if (values.searchTitle.trim() === "") {
                    errors.searchTitle = "Title of search is required";
                  }
                  return errors;
                }}
              >
                {({ isValid, ...props }) => (
                  <Form>
                    <div
                      className="model-body d-flex justify-content-center align-items-center flex-flow-column"
                      style={{ marginTop: "15px" }}
                    >
                      <div className="form-field-group">
                        <Field {...props} name="modalFieldValue">
                          {({ field, form: { setFieldValue }, meta }) => (
                            <TextboxCustom
                              {...field}
                              required={true}
                              name="searchTitle"
                              placeholder={
                                modelData.searchText
                                  ? modelData.searchText
                                  : "Title of search"
                              }
                              label="Title of search"
                              value={searchTitle}
                              onChange={(e) => {
                                setSearchTitle(
                                  capitalizeFirstLetterOnType(e.target.value)
                                );
                                setFieldValue(
                                  "searchTitle",
                                  capitalizeFirstLetterOnType(e.target.value)
                                );
                              }}
                              error={meta.touched && !isEmpty(meta.error)}
                              errorText={meta.touched && meta.error}
                            />
                          )}
                        </Field>
                      </div>
                      <div className="form-field-group">
                        <DropdownCustom
                          width="350"
                          name="notification_frequency"
                          label="Notification frequency"
                          justifyContent="left"
                          options={NOTIFICATION_FREQUENCY}
                          value={NOTIFICATION_FREQUENCY.find(
                            (rec) => rec.value === notificationFrequency
                          )}
                          onChange={(e) => setNotificationFrequency(e.value)}
                        />
                      </div>
                      <div className="btn">
                        <ButtonCustom
                          width="238"
                          onClick={callSaveSearchAPI}
                          isDisabled={!isValid}
                        >
                          {modelData.btnText}
                        </ButtonCustom>
                      </div>
                      <a
                        href="/#"
                        className="ngray-link"
                        style={{ marginTop: "30px" }}
                        onClick={(e) => {
                          e.preventDefault();
                          handleModel({ ...modelData, open: false });
                        }}
                        aria-label="cancel"
                      >
                        Cancel
                      </a>
                    </div>{" "}
                  </Form>
                )}
              </Formik>
            </CustomDialog>
          )}
          {showBoostPopup && (
            <BoostPopup
              message={
                totalTrueValues === statusList.length
                  ? "Your profile boost is complete"
                  : "You've just completed your first job search."
              }
              sub_message={
                totalTrueValues === statusList.length
                  ? "You're ready to gain the most from the Interstride platform."
                  : "You're on the way to gaining the most from the Interstride platform."
              }
              totalSteps={statusList.length}
              totalTrueValues={parseInt(totalTrueValues)}
              closeFn={closeFN}
            />
          )}
        </div>
      </div>
    </SearchResultStyles>
  );
};

export default SearchResult;
