import React from "react";

import ButtonCustom from "../../ButtonCustom/ButtonCustom";

import OPTFooterStyles from "./OPTFooterStyles";

import { useAppState } from "../../../context";
import useOPTPersistState from "../../../state/useOPTPersistState";

import btnArrow from "../../../assets/svg/f-btn-arrow-gray.svg";

const OPTFooter = () => {
  const { optPersistState = {}, updateOPTIndex = () => {} } =
    useOPTPersistState();
  const {
    optIndex = null,
    isNextDisabled = false, // optIndex has null value as default hence provided
    optStatusList = [],
  } = optPersistState;
  const { handleOPTNextAction = () => {} } = useAppState("optCalculator");
  return (
    <OPTFooterStyles>
      <div className="test-action-btns-wrapper mt-30">
        {optIndex > 0 ? (
          <ButtonCustom
            width={248}
            className="shadow-btn"
            onClick={(e) => {
              e.preventDefault();
              updateOPTIndex(optIndex - 1);
            }}
          >
            <img src={btnArrow} alt="btn-arrow" />
            Previous
          </ButtonCustom>
        ) : (
          ""
        )}
        <ButtonCustom
          width={248}
          onClick={(e) => {
            e.preventDefault();
            window.scrollTo(0, 0);
            handleOPTNextAction(optIndex);
          }}
          isDisabled={isNextDisabled}
        >
          {optIndex === optStatusList?.length - 1 ? "Finish" : "Next"}
        </ButtonCustom>
      </div>
    </OPTFooterStyles>
  );
};

export default OPTFooter;
