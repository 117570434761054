import React from "react";
import MiddlePageStyles from "../../HelpCenter/MiddlePage/MiddlePageStyles";
import { Grid } from "@material-ui/core";
import CardCustom from "../../CardCustom/CardCustom";

const MiddlePage = ({ data, ActiveID, name }) => {
  return (
    <MiddlePageStyles>
      <div className="started-middle-page">
        <div className="title-primary mb-25">{name ? name : "Overview"}</div>
        <div className="header-primary mb-5">
          Last Updated : January 1, 2022
        </div>
        <Grid item xs={12}>
          <CardCustom className="policy-section">
            <p dangerouslySetInnerHTML={{ __html: data }} />
          </CardCustom>
        </Grid>
      </div>
    </MiddlePageStyles>
  );
};

export default MiddlePage;
