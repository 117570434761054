import styled from "styled-components";

export default styled.div.attrs({ className: "" })`
  .opt_full-title {
    font-family: "TTCommons-Medium";
    font-size: 22px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 22px;
    text-align: center;
    margin: 0 0 15px 0;
    color: var(--primary-dark-color);
    padding-top: 2px;
    .alert-icon {
      margin: 0;
    }
    img {
      vertical-align: middle;
      margin: -4px 0 0 5px;
    }
    @media (max-width: 767px) {
      font-size: 18px;
      line-height: 19px;
      margin: 0 0 10px 0;
    }
  }
  .opt_sub-title {
    font-family: "TTCommons-Regular";
    font-size: 18px;
    letter-spacing: 0;
    line-height: 21px;
    text-align: center;
    color: var(--primary-dark-color);
    font-weight: 400;
    max-width: 602px;
    margin: 0 auto 15px;
    @media (max-width: 767px) {
      font-size: 15px;
      line-height: 18px;
      margin: 0 0 10px 0;
    }
  }
`;
