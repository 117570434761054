import React from "react";

import RightSidebarCard from "./RightSidebarCard/RightSidebarCard";

import OPTCalculatorRightSidebarStyles from "./OPTCalculatorRightSidebarStyles";

import useOPTPersistState from "../../../../state/useOPTPersistState";

const OPTCalculatorRightSidebar = () => {
  const { optPersistState = {} } = useOPTPersistState();
  const {
    optCalculatorState = {},
    optRightSidebarStatus = {},
    optIndex = null,
  } = optPersistState;
  if (
    (optIndex === 0 && !optCalculatorState?.full_time_cpt_used?.isAuthorized) ||
    (optIndex === 1 &&
      !optCalculatorState?.available_post_completion_opt?.isAuthorized)
  ) {
    return <></>;
  }

  if (optIndex === 0 && !optRightSidebarStatus?.[0]?.[0]?.value) {
    return <></>;
  }

  if (
    optIndex === 1 &&
    !optRightSidebarStatus?.[1]?.[1]?.value &&
    !optRightSidebarStatus?.[1]?.[2]?.value
  ) {
    return <></>;
  }

  if (
    optIndex === 2 &&
    (!optRightSidebarStatus?.[2]?.[0]?.value ||
      optRightSidebarStatus?.[2]?.[0]?.value === "Invalid date") &&
    (!optRightSidebarStatus?.[2]?.[1]?.value ||
      optRightSidebarStatus?.[2]?.[1]?.value === "Invalid date") &&
    (!optRightSidebarStatus?.[2]?.[2]?.value ||
      optRightSidebarStatus?.[2]?.[2]?.value === "Invalid date") &&
    (!optRightSidebarStatus?.[2]?.[3]?.value ||
      optRightSidebarStatus?.[2]?.[3]?.value ===
        "Invalid date and Invalid date")
  ) {
    return <></>;
  }

  if (optIndex === 3 && !optRightSidebarStatus?.[3]?.[0]?.value) {
    return <></>;
  }

  if (
    optIndex === 4 &&
    (!optRightSidebarStatus?.[4]?.[0]?.value ||
      optRightSidebarStatus?.[4]?.[0]?.value === "Invalid date") &&
    (!optRightSidebarStatus?.[4]?.[1]?.value ||
      optRightSidebarStatus?.[4]?.[1]?.value === "Invalid date") &&
    (!optRightSidebarStatus?.[4]?.[2]?.value ||
      optRightSidebarStatus?.[4]?.[2]?.value === "Invalid date") &&
    (!optRightSidebarStatus?.[4]?.[3]?.value ||
      optRightSidebarStatus?.[4]?.[3]?.value === "Invalid date") &&
    (!optRightSidebarStatus?.[4]?.[4]?.value ||
      optRightSidebarStatus?.[4]?.[4]?.value === "Invalid date")
  ) {
    return <></>;
  }

  if (
    optIndex === 5 &&
    (!optRightSidebarStatus?.[5]?.[0]?.value ||
      optRightSidebarStatus?.[5]?.[0]?.value === "Invalid date")
  ) {
    return <></>;
  }

  return (
    <>
      <OPTCalculatorRightSidebarStyles>
        {optRightSidebarStatus?.[optIndex]?.map((data) => (
          <RightSidebarCard {...data} />
        ))}
      </OPTCalculatorRightSidebarStyles>
      {optIndex === 4 ? (
        <p className="estimate-condition">
          *Assumes an additional 10-day mailing period.
        </p>
      ) : (
        ""
      )}
    </>
  );
};

export default OPTCalculatorRightSidebar;
