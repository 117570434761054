import React, { useState, useEffect } from "react";
import { isEmpty } from "lodash";

import usePromise from "../../../hooks/usePromise/usePromise";
import { widgetNetworksAPI } from "../../../services/widgetServices";
import { widgetRedirection } from "../../../utils/common";
import NetworkStyles from "./NetworkStyles";
import EmptyListWithImage from "../../../components/EmptyListWithImage/EmptyListWithImage";
import { InfoPanel } from "../../../components/InfoPanel/InfoPanel";
import ButtonCustom from "../../../components/ButtonCustom/ButtonCustom";
import JobsListingEffect from "../../../components/effects/JobsListingEffect/JobsListingEffect";
import WidgetOverlay from "../WidgetOverlay/WidgetOverlay";

import EmptyNetworkImage from "../../../assets/svg/discover_no_people.svg";

const Network = ({ token }) => {
  const [networks, setNetworks] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [callingWidgetNetworksAPI, refreshWidgetNetworksAPI] =
    usePromise(widgetNetworksAPI);

  useEffect(() => {
    refreshWidgetNetworksAPI(token);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    if (
      callingWidgetNetworksAPI.hasFetched() &&
      callingWidgetNetworksAPI.hasErrors() === false &&
      callingWidgetNetworksAPI.data()?.data
    ) {
      const { networks_list = [] } = callingWidgetNetworksAPI.data()?.data;
      setNetworks((networks_list ?? []).slice(0, 6));
      setShowLoader(false);
    } else if (
      callingWidgetNetworksAPI.hasFetched() &&
      callingWidgetNetworksAPI.hasErrors() === true
    ) {
      setNetworks([]);
      setShowLoader(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callingWidgetNetworksAPI.isFetching()]);

  const onMessageClick = () => {
    widgetRedirection("network");
  };

  return (
    <NetworkStyles>
      <div className="network-tab-content-wrapper">
        <div className="widget-network-tab">
          <h2 className="title">Meet student, alumni, mentor and more.</h2>
          <div className="network-list-wrapper">
            {showLoader ? (
              <JobsListingEffect count={5} />
            ) : networks && !isEmpty(networks) ? (
              <>
                {networks.map((item, index) => {
                  let tags = [];
                  if (item?.account_type === "alumni") {
                    tags.push(item?.account_type);
                  }
                  if (item.user_type && item.user_type.includes("Ambassador")) {
                    tags.push("Ambassador");
                  }
                  if (item?.mentor) {
                    tags.push("Mentor");
                  }
                  return (
                    <InfoPanel
                      variant="discover-network"
                      key={index}
                      index={index}
                      avatar={item.user_profile}
                      userAvatar={true}
                      title={item.full_name}
                      subTitle={[`${item.major}`, `${item.graduation_year}`]}
                      tags={tags}
                      isFavorites={false}
                      onClick={(e) => {}}
                      onPanelClick={(e) => {}}
                      intro={
                        item.country_image !== "" && (
                          <>
                            <div className="c-flag">
                              <img
                                src={item.country_image}
                                height="15px"
                                width="22px"
                                alt={`Flag of ${item.country_of_origin}`}
                              />
                            </div>
                            <div style={{ marginLeft: "8px" }}>
                              {item.country_of_origin}
                            </div>
                          </>
                        )
                      }
                      action={
                        <ButtonCustom
                          height={30}
                          width={100}
                          className={"bg-widget"}
                          onClick={onMessageClick}
                          aria-label={`Message ${item.full_name}`}
                        >
                          Message
                        </ButtonCustom>
                      }
                      marginTop="0px"
                    />
                  );
                })}
                <WidgetOverlay
                  title="Get full access to chat and join topics"
                  route_type="network"
                />
              </>
            ) : (
              <EmptyListWithImage
                Image={EmptyNetworkImage}
                title={"Looks like there are no people."}
                containerClassname={"empty-discover-people"}
              />
            )}
          </div>
        </div>
      </div>
    </NetworkStyles>
  );
};

export default Network;
