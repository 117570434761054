import React from "react";

import { useDropzone } from "react-dropzone";
import { createModuleStyleExtractor } from "../../utils/css";
import styles from "./CustomUpload.module.scss"

const cx = createModuleStyleExtractor(styles)

const CustomUploadV2 = ({
  handleUploadFile = () => {},
  handleRemoveFile = () => {},
  uploadText = "",
  removeText = "",
  vallidFiles= "image/*",
  preview = ""
}) => {
  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    multiple: false,
    noDrag: true,
    onDrop: handleUploadFile,
  });
  return (
    <div className={cx("custom-upload")}>
      <div
        className="dropzone-custom"
        {...getRootProps({
          className: "dropzone cursor-pointer",
        })}
        onClick={open}
      >
        <label for={`file-${uploadText}`} className="label-hidden">
          {uploadText}
        </label>
        <input
          id={`file-${uploadText}`}
          {...getInputProps()}
          accept={vallidFiles}
        />
      </div>
      <div className={cx("custom-upload__controls")}>
        {preview ? (
          <a href="#" onClick={handleRemoveFile}>
            {removeText}
          </a>
        ) : (
          <a href="#" onClick={open}>
            {uploadText}
          </a>
        )}
      </div>
    </div>
  );
};

export default CustomUploadV2;
