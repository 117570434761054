import styled from "styled-components";

export default styled.div.attrs({ className: "" })`
  text-align: center;
  h2 + .img-container {
    margin-top: 30px;
  }
  .img-container {
    height: 76px;
    width: 76px;
    background-color: var(--link-color);
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    margin: 0 auto 15px;
  }
  p {
    color: var(--primary-dark-color);
    font-family: "TTCommons-Medium";
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 19px;
    text-align: center;
    max-width: 368px;
    margin: 0 auto;
  }
`;
