import React from "react";
import { Avatar, Tooltip } from "@material-ui/core";
import cls from "classnames";
import { isEmpty, isString, isArray } from "lodash";

import InfoPanelStyles from "./InfoPanelStyles";
import CardCustom from "../CardCustom/CardCustom";
import StarIcon from "../StarIcon/StarIcon";
import { NoUserImage } from "../../helper/helper";
import InitialAvatar from "../InitialAvatar/InitialAvatar";
import redCheckedIcon from "../../assets/svg/red-check-mark.svg";

export const InfoPanel = ({
  index = null,
  avatar = null,
  userAvatar = false,
  notiPopUp = false,
  title,
  subTitle = [],
  intro,
  action,
  isActive = false,
  tags = [],
  isFavorites = false,
  avatarBackground = false,
  displayStar = true,
  avatarHeight = "100%",
  avatarWidth = "100%",
  onClick = () => {},
  onPanelClick = () => {},
  isCardClick = false,
  noTabIndex = false,
  classname = "",
  displayCount = false,
  count,
  customContent = "",
  variant = "default",
  type = "",
  customCardClass = "",
  ariaHidden = false,
  tabIndex = 0,
}) => {
  const accessibilityHelp = localStorage.getItem("accessibilityHelp");
  return (
    <InfoPanelStyles
      avatarHeight={avatarHeight}
      avatarWidth={avatarWidth}
      avatarBackground={avatarBackground}
    >
      <a
        href="/#"
        onClick={
          isCardClick
            ? onClick
            : (e) => {
                e.preventDefault();
              }
        }
        className={`info-panel ${classname}`}
        aria-hidden={ariaHidden}
        tabIndex={tabIndex}
        {...(ariaHidden ? { tabIndex: -1 } : {})}
      >
        <CardCustom
          className={cls("info-panel-card", customCardClass, {
            "panel-active": isActive,
          })}
          onClick={isCardClick ? onClick : onPanelClick}
          noTabIndex={noTabIndex}
          ariaHidden={ariaHidden}
        >
          {avatar && avatar.length > 0 && avatar !== null ? (
            <Avatar alt="Infopanel avatar" src={avatar} />
          ) : (
            <>
              {userAvatar ? (
                <InitialAvatar title={title} index={index} />
              ) : (
                <Avatar alt="No user placeholder" src={NoUserImage} />
              )}
            </>
          )}
          <div
            className={`panel-header ${notiPopUp ? "header-custom-width" : ""}`}
          >
            <div className="panel-title">
              <Tooltip title={title} placement="bottom">
                <div>{title}</div>
              </Tooltip>
              {isArray(tags) &&
                tags.length > 0 &&
                tags?.map((item, i) => {
                  if (!item) return <></>;
                  return (
                    <Tooltip
                      key={i}
                      title={
                        item === "Ambassador"
                          ? "Contact your admissions office to become an Ambassador."
                          : item === "Mentor"
                          ? "Contact your career services office to become a Mentor."
                          : ""
                      }
                      placement="bottom"
                      enterTouchDelay={0}
                      leaveTouchDelay={1000}
                    >
                      <div className="tag-item-wrapper">
                        <div
                          className={`tag-item ${
                            item === "Admissions" ||
                            item === "Ambassador" ||
                            item === "admissions" ||
                            item === "ambassador"
                              ? "tag-item--admissions"
                              : item === "External" ||
                                item === "Community Creator" ||
                                item === "Content Creator"
                              ? "tag-item--external"
                              : item === "Student" ||
                                item === "Alumni" ||
                                item === "student" ||
                                item === "alumni"
                              ? "tag-item--alumni"
                              : item === "Mentor" || item === "mentor"
                              ? "tag-item--mentor"
                              : item === "Admin" ||
                                item === "admin" ||
                                item === "Staff" ||
                                item === "staff" ||
                                item === "Interstride Admin"
                              ? "tag-item--blue"
                              : ""
                          }`}
                        >
                          {item === "Ambassador" && (
                            <img src={redCheckedIcon} alt="red-check" />
                          )}
                          <span>{item}</span>
                        </div>
                      </div>
                    </Tooltip>
                  );
                })}
              {isString(tags) && tags.length > 0 && (
                <Tooltip
                  title={
                    tags === "Ambassador"
                      ? "Contact your admissions office to become an Ambassador."
                      : tags === "Mentor"
                      ? "Contact your career services office to become a Mentor."
                      : ""
                  }
                  placement="bottom"
                  enterTouchDelay={0}
                  leaveTouchDelay={1000}
                >
                  <div className="tag-item-wrapper">
                    <div
                      className={`tag-item ${
                        tags === "Admissions" ||
                        tags === "Ambassador" ||
                        tags === "admissions" ||
                        tags === "ambassador"
                          ? "tag-item--admissions"
                          : tags === "External" ||
                            tags === "Community Creator" ||
                            tags === "Content Creator"
                          ? "tag-item--external"
                          : tags === "Student" ||
                            tags === "Alumni" ||
                            tags === "student" ||
                            tags === "alumni"
                          ? "tag-item--alumni"
                          : tags === "Mentor" || tags === "mentor"
                          ? "tag-item--mentor"
                          : tags === "Admin" ||
                            tags === "admin" ||
                            tags === "Staff" ||
                            tags === "staff" ||
                            tags === "Interstride Admin"
                          ? "tag-item--blue"
                          : ""
                      }`}
                    >
                      {tags === "Ambassador" && (
                        <img src={redCheckedIcon} alt="red-check" />
                      )}
                      <span>{tags}</span>
                    </div>
                  </div>
                </Tooltip>
              )}
              {displayCount && count && !isEmpty(count) && (
                <div className="notify-container">
                  <span className={`notify ${isActive && "active"}`}>
                    {count}
                  </span>
                </div>
              )}
            </div>
            <div className="panel-title">
              {type && (
                <div className="tags">
                  <div className="tag-item">{type}</div>
                </div>
              )}
              {subTitle?.length > 0 && (
                <div className="panel-sub-title">
                  {subTitle && typeof subTitle === "object" ? (
                    <p className="m-0">
                      {subTitle[0]}
                      {subTitle[1] && (
                        <>
                          <span>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</span>
                          {subTitle[1]}
                        </>
                      )}
                    </p>
                  ) : (
                    subTitle
                  )}
                </div>
              )}
            </div>
            <div className="panel-intro">{intro}</div>
            {customContent}
          </div>
          {variant === "discover-network" ? (
            <div className="panel-actions--discover">
              <div
                className={`panel-actions--discover__action ${
                  accessibilityHelp === "true" &&
                  " panel-actions--discover__showActions"
                }`}
              >
                {action}
              </div>
              <div
                className={`panel-actions--discover__star ${
                  isFavorites && "panel-actions--discover__star--active"
                } ${
                  accessibilityHelp === "true" &&
                  "panel-actions--discover__showActions"
                }`}
              >
                {displayStar &&
                  (isFavorites ? (
                    <StarIcon
                      aria-label={`Remove ${title} from favourites`}
                      variant="filled"
                      onClick={onClick}
                    />
                  ) : (
                    <StarIcon
                      aria-label={`Add ${title} to favourites`}
                      onClick={onClick}
                    />
                  ))}
              </div>
            </div>
          ) : variant === "actions-variant" ? (
            <div className="panel-actions--discover">
              <div
                className={`panel-actions--discover__addedAction ${
                  accessibilityHelp === "true" &&
                  " panel-actions--discover__showActions"
                }`}
              >
                {action}
              </div>
              <div
                className={`panel-actions--discover__star ${
                  isFavorites && "panel-actions--discover__star--active"
                } ${
                  accessibilityHelp === "true" &&
                  "panel-actions--discover__showActions"
                }
                `}
              >
                {displayStar &&
                  (isFavorites ? (
                    <StarIcon
                      aria-label={`Remove ${title} from favourites`}
                      variant="filled"
                      onClick={onClick}
                    />
                  ) : (
                    <StarIcon
                      aria-label={`Add ${title} to favourites`}
                      onClick={onClick}
                    />
                  ))}
              </div>
            </div>
          ) : (
            <div
              className={`panel-actions ${
                accessibilityHelp === "true" && "show-actions"
              }`}
            >
              {action}
              {displayStar &&
                (isFavorites ? (
                  <StarIcon
                    aria-label={`Remove ${title} from favourites`}
                    variant="filled"
                    onClick={onClick}
                  />
                ) : (
                  <StarIcon
                    aria-label={`Add ${title} to favourites`}
                    onClick={onClick}
                  />
                ))}
            </div>
          )}
        </CardCustom>
      </a>
    </InfoPanelStyles>
  );
};
