import React, { useEffect } from "react";

import OPTQueryContainer from "../../../../../components/OPTCalculator/OPTQueryContainer/OPTQueryContainer";
import CustomDatePicker from "../../../../../components/CustomDatePicker/CustomDatePicker";

import FormI765DeadlineStyles from "./FormI765DeadlineStyles";

import useOPTPersistState from "../../../../../state/useOPTPersistState";

import { isFormattedDateValid } from "../../../../../utils/common";

const FormI765Deadline = () => {
  const {
    optPersistState = {},
    updateFormI765Deadline = () => {},
    disableNext = () => {},
  } = useOPTPersistState();

  const { optCalculatorState = {} } = optPersistState;
  const { form_i765_filing_deadline = {} } = optCalculatorState;
  const { value = null } = form_i765_filing_deadline; // null value is intended to keep the input clear and show placeholder

  useEffect(() => {
    if (!value) {
      disableNext(true);
    } else {
      disableNext(false);
    }
  }, [value]);
  const handleDateChange = (date) => {
    updateFormI765Deadline({
      value: isFormattedDateValid(date, "LL") ? date : null,
    });
  };
  return (
    <FormI765DeadlineStyles>
      <OPTQueryContainer title={" Enter the date the DSO recommended OPT:"}>
        <CustomDatePicker
          dateFormat={"MMMM dd, yyyy"}
          width={200}
          type="date"
          onChange={handleDateChange}
          value={value !== null ? new Date(value) : null}
          placeholder="Enter date"
          isClearable
        />
      </OPTQueryContainer>
    </FormI765DeadlineStyles>
  );
};

export default FormI765Deadline;
