import React, { useState, useEffect } from "react";
import { Link } from "@reach/router";
import SearchFilterStyles from "./SearchFilterStyles";
import { Grid, Button } from "@material-ui/core";
import Textbox from "../../../Textbox/TextboxCustom";
import ButtonCustom from "../../../ButtonCustom/ButtonCustom";
import CardCustom from "../../../CardCustom/CardCustom";
import DropdownCustom from "../../../DropdownCustom/DropdownCustom";
import { useAppState } from "../../../../context";
import usePromise from "../../../../hooks/usePromise/usePromise";
import { searchWebinarAPI } from "../../../../services/webinarServices";
import FilterIcon from "../../../../assets/svg/filter-Icon.svg";
import FilledFilterIcon from "../../../../assets/svg/filled-filter-Icon.svg";
import searchIcon from "../../../../assets/svg/f-search.svg";

//Check if a value exists in 2d array.
function exists(array, search, key) {
  if (array) {
    return array.some((row) => {
      if (key) {
        return row[key] === search;
      } else {
        return row.includes(search);
      }
    });
  }
  return false;
}

function filterCatgories(array, value, key) {
  if (array.length > 0) {
    return array.filter((i) => {
      return i[key] !== value;
    });
  }
}

const trainingIdArray = ["Admin Training", "Student Training"];

const SearchFilter = ({
  categoryOptions = [],
  setUpcomingAndPastWebinars,
  setLoader = () => {},
}) => {
  const [openFilter, toggleFilter] = useState(false);
  const { setWebinars } = useAppState("learn");
  const [search, setSearch] = useState("");
  const [categories, setCategories] = useState([]);
  const [tags, setTags] = useState([]);
  const [tagOptions, setTagOptions] = useState([]);
  const [customCategoriesOptions, setCustomCategoriesOptions] =
    useState(categoryOptions);
  const [finalCategories, setFinalCategories] = useState([]);
  const [host, setHost] = useState("");
  const [callSearchWebinarAPI, refreshSearchWebinarAPI] =
    usePromise(searchWebinarAPI);

  const clearFilters = () => {
    setHost("");
    setSearch("");
    setCategories([]);
    setTags([]);
    setFinalCategories([]);
    setTimeout(() => {
      refreshSearchWebinarAPI();
    }, 1000);
  };

  const handleSearchFilter = (finalCategoryArray) => {
    setLoader(true);
    let categoryArray = finalCategories;
    if (tags.length === 0 && exists(categories, 0, "value")) {
      categoryArray = [...new Set([...tagOptions, ...finalCategories])];
    }
    toggleFilter(false);
    // refreshSearchWebinarAPI(search, host.value);
    refreshSearchWebinarAPI(
      search,
      host,
      categoryArray !== null ? categoryArray : finalCategoryArray
    );
  };

  useEffect(() => {
    if (
      callSearchWebinarAPI.hasFetched() &&
      callSearchWebinarAPI.hasErrors() === false
    ) {
      console.info("--------------------------");
      console.info("callSearchWebinarAPI =>", callSearchWebinarAPI);
      console.info("--------------------------");
      setWebinars(callSearchWebinarAPI.data().data);
      setUpcomingAndPastWebinars(callSearchWebinarAPI.data().data);
      setLoader(false);
    }
  }, [callSearchWebinarAPI.isFetching()]);

  useEffect(() => {
    let count = 0;
    let options = [];
    let final = (categoryOptions || []).filter((i) => {
      if (!trainingIdArray.includes(i?.label)) {
        return i;
      } else {
        if (Array.isArray(tagOptions)) {
          options.push(i);
        }
        count++;
      }
    });
    setTagOptions(options);
    if (count > 0) {
      final.unshift({ value: 0, label: "Training" });
    }
    setCustomCategoriesOptions(final);
  }, [categoryOptions]);

  const setCategoriesSelected = (e, category) => {
    setCustomCategoriesOptions(
      customCategoriesOptions.map((cat) =>
        cat.value === category ? { ...cat, selected: !cat.selected } : cat
      )
    );
    let finalCategoryArray = finalCategories;
    if (!finalCategories.includes(category)) {
      finalCategoryArray.push(category);
    } else {
      finalCategoryArray.splice(finalCategoryArray.indexOf(category), 1);
    }
    setFinalCategories(finalCategoryArray);
    handleSearchFilter(finalCategoryArray);
  };

  return (
    <SearchFilterStyles>
      <div className="f-search-wrapper f-search-wrapper-webinars">
        <div className="fsearch-box">
          <Textbox
            name="search_keyword"
            type="text"
            placeholder="Search keyword"
            label=""
            lableaignment="left"
            value={search}
            onChange={(obj) => setSearch(obj.target.value)}
            onEnterKey={() => {
              handleSearchFilter();
            }}
          />
          <a
            className="search-btn"
            href="#"
            onClick={(e) => {
              e.preventDefault();
              handleSearchFilter();
            }}
          >
            <img src={searchIcon} alt="Search icon" />
          </a>
        </div>
        {customCategoriesOptions.length > 0 && (
          <div className="filter-item">
            <label>Filter</label>
            <div className="filter-item-content">
              {customCategoriesOptions.map((item, i) => {
                //console.log(item)
                return (
                  <a
                    href="#"
                    key={i}
                    onClick={(e) => {
                      e.preventDefault();
                      setCategoriesSelected(e, item.value);
                    }}
                    className={`item ${
                      item.selected || finalCategories.includes(item.value)
                        ? "active"
                        : ""
                    }`}
                    dataId={item.value}
                    aria-label={item.label}
                    disableRipple={true}
                    aria-selected={
                      item.selected || finalCategories.includes(item.value)
                        ? true
                        : false
                    }
                  >
                    {item.label}
                  </a>
                );
              })}
            </div>
          </div>
        )}
        {(finalCategories.length > 0 || search) && (
          <div className="clear-filter">
            <Link
              to="/#"
              onClick={(e) => {
                e.preventDefault();
                clearFilters();
              }}
              className="ngray-link"
            >
              Clear all
            </Link>
          </div>
        )}
      </div>
    </SearchFilterStyles>
  );
};
export default SearchFilter;
