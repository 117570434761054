import { useEffect, useState } from "react";
import { size, filter, isEmpty } from "lodash";
import moment from "moment";

import useOPTPersistState from "./useOPTPersistState";
import { getDateDiffInDays } from "../utils/common";

const useOPTCalculator = () => {
  const [restoringState, setRestoringState] = useState(false);
  const {
    optPersistState = {},
    setOPTPersistState = () => {},
    updateOPTRightSidebarStatus = () => {},
    resetOPT = () => {},
  } = useOPTPersistState();

  const {
    optCalculatorState = {},
    optStatusList = [],
    optRightSidebarStatus = [],
  } = optPersistState;

  const {
    full_time_cpt_used = {},
    available_post_completion_opt = {},
    deadlines_post_completion_opt = {},
    dso_uscis_processing_times = {},
    preferred_opt_start_date = {},
    form_i765_filing_deadline = {},
  } = optCalculatorState;

  useEffect(() => {
    if (
      optRightSidebarStatus?.length === 0 ||
      optStatusList?.length === 0 ||
      isEmpty(optCalculatorState)
    ) {
      setRestoringState(true);
      resetOPT();
      setRestoringState(false);
    }
  }, []);

  useEffect(() => {
    if (
      full_time_cpt_used &&
      !isEmpty(full_time_cpt_used) &&
      !restoringState &&
      optRightSidebarStatus.length !== 0
    ) {
      const syncFullTimeCPTUsed = () => {
        const total = full_time_cpt_used?.value?.reduce(getDateDiffInDays, 0);
        const clonedRightSidebarStatus = [...optRightSidebarStatus];
        if (clonedRightSidebarStatus[0][0]) {
          clonedRightSidebarStatus[0][0].value = total;
        }
        return clonedRightSidebarStatus;
      };
      // calculate diff for all the arrays
      updateOPTRightSidebarStatus(syncFullTimeCPTUsed());
    }
  }, [full_time_cpt_used]);

  useEffect(() => {
    if (
      available_post_completion_opt &&
      !isEmpty(available_post_completion_opt) &&
      !restoringState &&
      optRightSidebarStatus.length !== 0
    ) {
      const syncAvailablePostCompletionOPT = () => {
        const fullTimeTotal = available_post_completion_opt?.value
          ?.filter((val) => !val?.isPartTime)
          .reduce(getDateDiffInDays, 0);

        const partTimeTotal = available_post_completion_opt?.value
          ?.filter((val) => val?.isPartTime)
          .reduce(getDateDiffInDays, 0);

        const total =
          (partTimeTotal ? Number(partTimeTotal) / 2 : 0) +
          (fullTimeTotal ? Number(fullTimeTotal) : 0);

        const clonedRightSidebarStatus = [...optRightSidebarStatus];
        let available_opt = 0;
        if (clonedRightSidebarStatus[1][0]) {
          available_opt = clonedRightSidebarStatus[1][0].value - total;
        }

        if (clonedRightSidebarStatus[1][1]) {
          clonedRightSidebarStatus[1][1].value = total;
        }

        if (clonedRightSidebarStatus[1][2]) {
          clonedRightSidebarStatus[1][2].value =
            available_opt > 0 ? available_opt : 0;
        }

        return clonedRightSidebarStatus;
      };
      // calculate diff for all the arrays
      updateOPTRightSidebarStatus(syncAvailablePostCompletionOPT());
    }
  }, [available_post_completion_opt]);

  useEffect(() => {
    if (
      deadlines_post_completion_opt &&
      !isEmpty(deadlines_post_completion_opt) &&
      !restoringState &&
      optRightSidebarStatus.length !== 0
    ) {
      const syncDeadlinesPostCompletionOPT = () => {
        const add60Days = moment(deadlines_post_completion_opt?.value)
          .add(60, "d")
          .format("MMMM DD, YYYY");
        const earliestI765USCISDate = moment(
          deadlines_post_completion_opt?.value
        )
          .subtract(90, "d")
          .format("MMMM DD, YYYY");
        const sevisDeadline = add60Days;
        const I765USCISDeadline = add60Days;
        const postCompletionOPTRange = `${moment(
          deadlines_post_completion_opt?.value
        )
          .add(1, "d")
          .format("MMMM DD, YYYY")} and ${add60Days}`;
        const clonedRightSidebarStatus = [...optRightSidebarStatus];

        if (clonedRightSidebarStatus[2][0]) {
          clonedRightSidebarStatus[2][0].value = earliestI765USCISDate;
        }

        if (clonedRightSidebarStatus[2][1]) {
          clonedRightSidebarStatus[2][1].value = sevisDeadline;
        }

        if (clonedRightSidebarStatus[2][2]) {
          clonedRightSidebarStatus[2][2].value = I765USCISDeadline;
        }

        if (clonedRightSidebarStatus[2][3]) {
          clonedRightSidebarStatus[2][3].value = postCompletionOPTRange;
        }

        return clonedRightSidebarStatus;
      };
      // calculate diff for all the arrays
      updateOPTRightSidebarStatus(syncDeadlinesPostCompletionOPT());
    }
  }, [deadlines_post_completion_opt]);

  useEffect(() => {
    if (
      dso_uscis_processing_times &&
      !isEmpty(dso_uscis_processing_times) &&
      !restoringState &&
      optRightSidebarStatus.length !== 0
    ) {
      const syncDSOUSCISProcessingTimes = () => {
        const {
          value: {
            dso,
            uscis: { months, weeks, days },
          },
        } = dso_uscis_processing_times;
        const clonedRightSidebarStatus = [...optRightSidebarStatus];
        if (clonedRightSidebarStatus[3][0]) {
          clonedRightSidebarStatus[3][0].value =
            Number(dso) +
            Number(months) * 30 +
            Number(weeks) * 7 +
            Number(days);
        }
        return clonedRightSidebarStatus;
      };
      // calculate diff for all the arrays
      updateOPTRightSidebarStatus(syncDSOUSCISProcessingTimes());
    }
  }, [dso_uscis_processing_times]);

  useEffect(() => {
    if (
      preferred_opt_start_date &&
      !isEmpty(preferred_opt_start_date) &&
      !restoringState &&
      optRightSidebarStatus.length !== 0
    ) {
      const syncPreferredOPTStartDate = () => {
        const {
          value: {
            dso,
            uscis: { months, weeks, days },
          },
        } = dso_uscis_processing_times;
        const USCISDays =
          Number(months) * 30 + Number(weeks) * 7 + Number(days);
        const clonedRightSidebarStatus = [...optRightSidebarStatus];
        const {
          value: { startDate = null }, // null value is intended to keep the input clear and show placeholder
        } = preferred_opt_start_date;
        const employmentAuthorizationDate = moment(startDate).isValid()
          ? moment(startDate).subtract(1, "d").format("MMMM DD, YYYY")
          : "";
        const estimatedUSCISProcessingDate = moment(
          employmentAuthorizationDate
        ).isValid()
          ? moment(employmentAuthorizationDate)
              .subtract(7, "d")
              .format("MMMM DD, YYYY")
          : "";
        const estimatedI765USCISReceiptDate = moment(
          estimatedUSCISProcessingDate
        ).isValid()
          ? moment(estimatedUSCISProcessingDate)
              .subtract(USCISDays, "d")
              .format("MMMM DD, YYYY")
          : "";
        const optSEVISEntryDate = moment(
          estimatedI765USCISReceiptDate
        ).isValid()
          ? moment(estimatedI765USCISReceiptDate)
              .subtract(10, "d")
              .format("MMMM DD, YYYY")
          : "";
        const requestOPTFromDSODeadline = moment(optSEVISEntryDate).isValid()
          ? moment(optSEVISEntryDate).subtract(dso, "d").format("MMMM DD, YYYY")
          : "";
        if (clonedRightSidebarStatus[4][0]) {
          clonedRightSidebarStatus[4][0].value = requestOPTFromDSODeadline;
        }

        if (clonedRightSidebarStatus[4][1]) {
          clonedRightSidebarStatus[4][1].value = optSEVISEntryDate;
        }

        if (clonedRightSidebarStatus[4][2]) {
          clonedRightSidebarStatus[4][2].value = estimatedI765USCISReceiptDate;
        }

        if (clonedRightSidebarStatus[4][3]) {
          clonedRightSidebarStatus[4][3].value = estimatedUSCISProcessingDate;
        }

        if (clonedRightSidebarStatus[4][4]) {
          clonedRightSidebarStatus[4][4].value = employmentAuthorizationDate;
        }
        return clonedRightSidebarStatus;
      };
      // calculate diff for all the arrays
      updateOPTRightSidebarStatus(syncPreferredOPTStartDate());
    }
  }, [preferred_opt_start_date]);

  useEffect(() => {
    if (
      form_i765_filing_deadline &&
      !isEmpty(form_i765_filing_deadline) &&
      !restoringState &&
      optRightSidebarStatus.length !== 0
    ) {
      const syncFormI765FilingDeadline = () => {
        const i765Deadline = moment(form_i765_filing_deadline?.value).isValid()
          ? moment(form_i765_filing_deadline?.value)
              .add(30, "d")
              .format("MMMM DD, YYYY")
          : "";
        const clonedRightSidebarStatus = [...optRightSidebarStatus];
        if (clonedRightSidebarStatus[5][0]) {
          clonedRightSidebarStatus[5][0].value = i765Deadline;
        }
        return clonedRightSidebarStatus;
      };
      // calculate diff for all the arrays
      updateOPTRightSidebarStatus(syncFormI765FilingDeadline());
    }
  }, [form_i765_filing_deadline]);

  const handleOPTNextAction = (updateIndexNumber = 0) => {
    const newOPTStatusList = Object.assign([], optStatusList);
    newOPTStatusList[updateIndexNumber].isCompleted = true;
    const total = size(filter(newOPTStatusList, { isCompleted: true }));

    setOPTPersistState((currentState) => ({
      ...currentState,
      optIndex: updateIndexNumber + 1,
      optStatusList: newOPTStatusList,
      optTotalCompletedStatus: total,
    }));
  };

  return {
    handleOPTNextAction,
  };
};

export default useOPTCalculator;
