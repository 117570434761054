import createPersistedState from "use-persisted-state";
import {
  OPT_CALCULATOR_RIGHT_SIDEBAR_SCHEMA,
  OPT_CALCULATOR_STATUS_LIST,
  optCalculatorInitialArgs,
} from "../constant/mockdata";

const useInterstrideOPTState = createPersistedState("interstride_opt");

const initialArgs = {
  // OPT Calculator
  optCalculatorState: { ...optCalculatorInitialArgs },
  optStatusList: [...OPT_CALCULATOR_STATUS_LIST],
  optRightSidebarStatus: [...OPT_CALCULATOR_RIGHT_SIDEBAR_SCHEMA],
  optIndex: null,
  isNextDisabled: false,
  optTotalCompletedStatus: 0,
};

const useOPTPersistState = () => {
  const [optPersistState, setOPTPersistState] = useInterstrideOPTState({
    ...JSON.parse(JSON.stringify(initialArgs)),
  });

  const updateFullTimeCPTUsed = (data) => {
    setOPTPersistState((currentState) => ({
      ...currentState,
      optCalculatorState: {
        ...optCalculatorInitialArgs,
        ...currentState.optCalculatorState,
        full_time_cpt_used: {
          ...optCalculatorInitialArgs.full_time_cpt_used,
          ...currentState.optCalculatorState.full_time_cpt_used,
          ...data,
        },
      },
    }));
  };

  const updateAvailablePostCompletionOPT = (data) => {
    setOPTPersistState((currentState) => ({
      ...currentState,
      optCalculatorState: {
        ...optCalculatorInitialArgs,
        ...currentState.optCalculatorState,
        available_post_completion_opt: {
          ...optCalculatorInitialArgs.available_post_completion_opt,
          ...currentState.optCalculatorState.available_post_completion_opt,
          ...data,
        },
      },
    }));
  };

  const updateDeadlinesOnPostCompletionOPT = (data) => {
    setOPTPersistState((currentState) => ({
      ...currentState,
      optCalculatorState: {
        ...optCalculatorInitialArgs,
        ...currentState.optCalculatorState,
        deadlines_post_completion_opt: {
          ...optCalculatorInitialArgs.deadlines_post_completion_opt,
          ...currentState.optCalculatorState.deadlines_post_completion_opt,
          ...data,
        },
      },
    }));
  };

  const updateDSOUSCIS = (data) => {
    setOPTPersistState((currentState) => ({
      ...currentState,
      optCalculatorState: {
        ...optCalculatorInitialArgs,
        ...currentState.optCalculatorState,
        dso_uscis_processing_times: {
          ...optCalculatorInitialArgs.dso_uscis_processing_times,
          ...currentState.optCalculatorState.dso_uscis_processing_times,
          ...data,
        },
      },
    }));
  };

  const updatePreferredOPTStartDate = (data) => {
    setOPTPersistState((currentState) => ({
      ...currentState,
      optCalculatorState: {
        ...optCalculatorInitialArgs,
        ...currentState.optCalculatorState,
        preferred_opt_start_date: {
          ...optCalculatorInitialArgs.preferred_opt_start_date,
          ...currentState.optCalculatorState.preferred_opt_start_date,
          ...data,
        },
      },
    }));
  };

  const updateFormI765Deadline = (data) => {
    setOPTPersistState((currentState) => ({
      ...currentState,
      optCalculatorState: {
        ...optCalculatorInitialArgs,
        ...currentState.optCalculatorState,
        form_i765_filing_deadline: {
          ...optCalculatorInitialArgs.form_i765_filing_deadline,
          ...currentState.optCalculatorState.form_i765_filing_deadline,
          ...data,
        },
      },
    }));
  };

  const updateOPTIndex = (idx) => {
    setOPTPersistState((currentState) => ({
      ...currentState,
      optIndex: idx,
    }));
  };

  const disableNext = (val) => {
    setOPTPersistState((currentState) => ({
      ...currentState,
      isNextDisabled: val,
    }));
  };

  const updateOPTTotalCompletedStatus = (val) => {
    setOPTPersistState((currentState) => ({
      ...currentState,
      optTotalCompletedStatus: val,
    }));
  };

  const updateOPTRightSidebarStatus = (data) => {
    setOPTPersistState((currentState) => ({
      ...currentState,
      optRightSidebarStatus: data,
    }));
  };

  const updatePersistedOPTStatusList = (data) => {
    setOPTPersistState((currentState) => ({
      ...currentState,
      optStatusList: data,
    }));
  };

  const resetOPT = () => {
    setOPTPersistState(() => {
      return {
        ...JSON.parse(JSON.stringify(initialArgs)),
      };
    });
  };

  return {
    updateOPTIndex,
    updateFullTimeCPTUsed,
    updateAvailablePostCompletionOPT,
    updateDeadlinesOnPostCompletionOPT,
    updateDSOUSCIS,
    updatePreferredOPTStartDate,
    updateFormI765Deadline,
    disableNext,
    updateOPTTotalCompletedStatus,
    updateOPTRightSidebarStatus,
    updatePersistedOPTStatusList,
    resetOPT,
    optPersistState,
    setOPTPersistState,
  };
};

export default useOPTPersistState;
