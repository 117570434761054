import { navigate } from "@reach/router";
import React, { useEffect } from "react";
import { isEmpty } from "lodash";
import { getUserById } from "../services/authenticationServices";
import usePromise from "../hooks/usePromise/usePromise";
import { applyAccessibilityHelp, clearLocalStorage } from "../helper/helper";
import usePersistState from "../state/usePersistState";

// there?
export function loginRequired(Child) {
  return function LoginRequired(props) {
    const { users = {}, updatePersistState } = usePersistState();
    const [callgetUserById, refreshgetUserById] = usePromise(getUserById);

    const authToken = localStorage.getItem("authToken");
    const email = localStorage.getItem("email");
    const userId = localStorage.getItem("userId");

    useEffect(() => {
      //userId && authToken && email && user === null
      if (userId && authToken && email && users === null) {
        //
        // console.log("user login1", userId, authToken, email, Child);
        refreshgetUserById(userId);
        // console.log("user login", user);
      } else {
        console.log("nothign available");
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      if (
        callgetUserById.hasFetched() &&
        callgetUserById.hasErrors() === false &&
        callgetUserById.data() &&
        callgetUserById.data().data &&
        callgetUserById.data().data.user_info
      ) {
        var users = {};
        users.authentication_token = authToken;
        updatePersistState({ users: callgetUserById.data().data.user_info });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [callgetUserById.isFetching()]);

    if (isEmpty(userId) || isEmpty(authToken) || isEmpty(email)) {
      // console.log("coming inside this");
      //return redirectTo("/login");
      //sessionStorage.clear();
      //localStorage.clear();
      navigate("/login");
      return null;
    } else {
      if (userId && authToken && email) {
        // eslint-disable-next-line react/react-in-jsx-scope
        return (
          <div>
            <Child {...props} />
          </div>
        );
      } else {
        window.location.href = "/";
      }
    }
  };
}

export function logOut() {
  // console.log("clear localstorage3");
  sessionStorage.clear();
  clearLocalStorage();
  applyAccessibilityHelp(false);
}
