import React, { useState, useEffect, Fragment, useRef } from "react";
import moment from "moment/moment";
import _filter from "lodash/filter";
import { makeStyles } from "@material-ui/core/styles";
import { navigate } from "@reach/router";
import Media from "react-media";

import MainLayoutStyles from "./MainLayoutStyles";
import { useAppState } from "../../../context";
import usePersistState from "../../../state/usePersistState";
import usePromise from "../../../hooks/usePromise/usePromise";
import Header from "../Header/Header";
import NotificationDialog from "../../../components/Notification/NotificationDialog/NotificationDialog";
import SubHeader from "../SubHeader/SubHeader";
import useClickOutsideV2 from "../../../hooks/useClickOutsideV2";
import ButtonCustom from "../../ButtonCustom/ButtonCustom";

import {
  notificationsListAPI,
  notificationReadCountAPI,
} from "../../../services/notificationServices";
import { getUserPermissionsAPI } from "../../../services/authenticationServices";

const useStyles = makeStyles((theme) => ({
  pageTitle: {
    color: theme.palette.primary.main,
    display: "flex",
    width: "100%",
    justifyContent: "center",
    fontSize: "42px",
    fontFamily: '"TTCommons-DemiBold"',
    margin: "38px 0 46px",
    lineHeight: "38px",
    padding: 0,
  },
}));

const MainLayout = ({
  setShowSignOutPopup,
  saveChangesClick,
  activeStep,
  title,
  children,
  getStarted,
  withSubHeader = false,
  subHeaderFixed = false,
  tabs = [],
  subHeaderList = [],
  breadCrumb = [],
  location = null,
  updatedUserDetails,
  viewOnlyNavbar = false,
  containerClassName = "",
  showSubheaderHeading = false,
  isProfile = false,
}) => {
  const classes = useStyles();

  const [activePath, setActivePath] = useState(null);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [notificatioReDirectionUrl, setNotificationReDirectionUrl] =
    useState(null);
  const [isFetching, setFetching] = useState(false);
  const [fixedHeader, setFixedHeader] = useState(null);
  const [notificationDialogOpen, setNotificationDialogOpen] = useState(false);

  const [callingNotificationReadCountAPI, refreshNotificationReadCountAPI] =
    usePromise(notificationReadCountAPI);
  const [callingNotificationsListAPI, refreshNotificationsListAPI] =
    usePromise(notificationsListAPI);
  const [callGetUserPermissionsAPI, refreshGetUserPermissionsAPI] = usePromise(
    getUserPermissionsAPI
  );

  const {
    updateUserPermissions,
    permissionsExpiry,
    decrementUnreadNotificationCount,
  } = usePersistState();
  const { setNotifications, notificationFilters } =
    useAppState("notifications");

  // Reference used to close notification drawer on click outside via custom hook
  const refNotificationDrawer = useRef();
  useClickOutsideV2(refNotificationDrawer, () =>
    setNotificationDialogOpen(false)
  );

  useEffect(() => {
    if (
      permissionsExpiry === null ||
      moment().isAfter(moment(permissionsExpiry))
    )
      refreshGetUserPermissionsAPI();
  }, []);

  useEffect(() => {
    if (
      callGetUserPermissionsAPI.hasFetched() &&
      callGetUserPermissionsAPI.hasErrors() === false
    ) {
      if (callGetUserPermissionsAPI.data()) {
        updateUserPermissions(callGetUserPermissionsAPI.data());
      }
    }
  }, [callGetUserPermissionsAPI.isFetching()]);

  useEffect(() => {
    if (
      callingNotificationReadCountAPI.hasFetched() &&
      callingNotificationReadCountAPI.hasErrors() === false
    ) {
      // Update count
      decrementUnreadNotificationCount();
      // Redirect user
      if (selectedItemId && selectedItemId != undefined) {
        navigate(notificatioReDirectionUrl + `?item_id=${selectedItemId}`);
      } else {
        navigate(notificatioReDirectionUrl);
      }
    }
  }, [callingNotificationReadCountAPI.isFetching()]);

  useEffect(() => {
    if (
      callingNotificationsListAPI.hasFetched() &&
      callingNotificationsListAPI.hasErrors() === false &&
      callingNotificationsListAPI.data() &&
      callingNotificationsListAPI.data().data
    ) {
      setFetching(false);
      setNotifications(callingNotificationsListAPI.data().data);
    }
  }, [callingNotificationsListAPI.isFetching()]);

  useEffect(() => {
    let filterBy = _filter(notificationFilters, (data) => {
      return data.checked === true;
    });
    setFetching(true);
    refreshNotificationsListAPI(filterBy);
  }, [location]);

  const readAndRedirectNotification = (
    notiId,
    notificationReDirectUrl,
    itemId
  ) => {
    setNotificationReDirectionUrl(notificationReDirectUrl);
    setSelectedItemId(itemId);
    refreshNotificationReadCountAPI(notiId);

    let filterBy = _filter(notificationFilters, (data) => {
      return data.checked === true;
    });
    setFetching(true);
    refreshNotificationsListAPI(filterBy);
  };

  useEffect(() => {
    if (typeof window != "undefined") {
      const changeNavigation = () => {
        if (window.scrollY >= 71) {
          setFixedHeader(true);
        } else if (window.scrollY < 71) {
          setFixedHeader(false);
        }
      };
      window.addEventListener("scroll", changeNavigation);
    }
    return () => {};
  }, []);

  const subHeaderContent = (condition, matches) => {
    return (
      <div
        style={{
          position: condition ? "fixed" : "static",
          zIndex: 1000,
          top: condition ? 0 : "auto",
          left: condition ? 0 : "auto",
          width: condition ? "99vw" : "100%",
          display: matches.small ? "block" : "flex",
          alignItems: "center",
          justifyContent: condition
            ? "space-between"
            : activeStep === 5 || !getStarted
            ? "flex-start"
            : "center",
          backgroundColor: condition ? "#FFFFFF" : "",
          padding: condition ? "0 60px 0 35px" : "auto",
        }}
      >
        <div style={{ display: "flex" }}>
          {window.location.pathname === "/profile" ? (
            <h1
              style={{ width: "auto" }}
              className={`${classes.pageTitle} h1-maintitle`}
            >
              {title}
            </h1>
          ) : null}
        </div>
        <div>
          {condition ? (
            <div
              style={{
                display: "flex",
                justifyContent: "right",
                width: matches.small ? "100%" : "auto",
                marginRight: -25,
              }}
            >
              <ButtonCustom
                style={{
                  marginRight: 10,
                  fontSize: 22,
                }}
                width={matches.small ? 95 : 173}
                height={matches.small ? 40 : 63}
                isDisabled={
                  Object.keys(updatedUserDetails).length === 0 &&
                  updatedUserDetails.constructor === Object
                    ? true
                    : false
                }
                onClick={saveChangesClick}
                type="submit"
              >
                Save
              </ButtonCustom>
              <ButtonCustom
                width={matches.small ? 95 : 173}
                height={matches.small ? 40 : 63}
                style={{
                  backgroundColor: "#8094AB",
                  fontSize: 22,
                }}
                className="bg-gray"
                onClick={() => setShowSignOutPopup(true)}
              >
                Sign Out
              </ButtonCustom>
            </div>
          ) : null}
        </div>
      </div>
    );
  };

  return (
    <MainLayoutStyles>
      <Media
        queries={{
          small: "(max-width: 599px)",
          medium: "(min-width: 600px) and (max-width: 1299px)",
          large: "(min-width: 1300px)",
        }}
      >
        {(matches) => (
          <Fragment>
            <header role="banner">
              <Header
                setShowSignOutPopup={setShowSignOutPopup}
                setActivePath={setActivePath}
                activeStep={activeStep}
                path={window.location.pathname}
                notificationDialogOpen={notificationDialogOpen}
                setNotificationDialogOpen={setNotificationDialogOpen}
              />
              <div ref={refNotificationDrawer}>
                <NotificationDialog
                  notificationDialogOpen={notificationDialogOpen}
                  setNotificationDialogOpen={setNotificationDialogOpen}
                  title="New Notifications"
                  isFetching={isFetching}
                  noTitle="Looks like you have no new notifications!"
                  isNew={true}
                  readApiCall={(notiId, notificationReDirectUrl, itemId) =>
                    readAndRedirectNotification(
                      notiId,
                      notificationReDirectUrl,
                      itemId
                    )
                  }
                />
              </div>

              {isProfile ? (
                <div className="layout-header-bar profile-header">
                  <div className="relative-div">
                    {subHeaderContent(false, matches)}
                    {subHeaderFixed && fixedHeader
                      ? subHeaderContent(true, matches)
                      : null}
                  </div>
                </div>
              ) : (
                <div className="layout-header-bar">
                  <div
                    className="relative-div"
                    style={{ display: viewOnlyNavbar ? "none" : "" }}
                  >
                    {breadCrumb.length > 0 && (
                      <div className="breadcrumb-div">
                        <nav
                          role="navigation"
                          aria-label="breadcrumb"
                          className="card-breadcrumb"
                        >
                          <ul>
                            {breadCrumb.map(({ title, path }, index) => (
                              <li key={index}>
                                {index === breadCrumb.length - 1 ? (
                                  <span aria-current="page">
                                    {title}
                                    <span className="sr-only">
                                      Current Page
                                    </span>
                                  </span>
                                ) : (
                                  <a
                                    aria-label={`Back to ${title}`}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      navigate(path);
                                    }}
                                    href="/#"
                                    className="link-focus"
                                  >
                                    {title}
                                  </a>
                                )}
                              </li>
                            ))}
                          </ul>
                        </nav>
                      </div>
                    )}
                    {showSubheaderHeading && (
                      <h1 className={`${classes.pageTitle} h1-maintitle`}>
                        {title}
                      </h1>
                    )}
                  </div>
                </div>
              )}

              {withSubHeader && (
                <>
                  <SubHeader
                    tabs={tabs}
                    subHeaderList={subHeaderList}
                    classes={classes}
                    pageTitle={title}
                    location={location}
                    activePath={activePath}
                    setActivePath={setActivePath}
                    viewOnlyNavbar={viewOnlyNavbar}
                  />
                </>
              )}
            </header>
            <main
              style={{
                ...(isProfile && {
                  marginTop: matches.small && activeStep === 5 ? 50 : "auto",
                  paddingTop:
                    window.location.pathname === "/profile" ? 0 : "auto",
                }),
              }}
              role="main"
            >
              <div
                id="main-page-content"
                className={
                  isProfile
                    ? ""
                    : `interstride-student-main-content-wrapper ${containerClassName}`
                }
              >
                {children}
              </div>
            </main>
          </Fragment>
        )}
      </Media>
    </MainLayoutStyles>
  );
};

export default MainLayout;
